.dashboard-main-header {
}

.dashboard-main-summary {
  display: flex;
}

.dashboard-main-client-blog-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
  margin-top: 20px;
  margin-bottom: 50px;
}

@media all and (max-width: 1100px) {
  .dashboard-main-client-blog-grid {
    grid-template-columns: 1fr;
  }
}

@media all and (max-width: 910px) {
  .dashboard-main-client-blog-grid {
    grid-template-columns: 1fr;
  }
}
