.all-services-action-icons {
  display: flex;
  box-sizing: border-box;
  gap: 20px;
  justify-content: center;
}

.all-services-action-edit {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.all-services-action-delete {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media all and (max-width: 767px) {
  .all-services-action-icons {
    justify-content: flex-start;
  }
}

@media all and (max-width: 666px) {
  .all-services-action-icons {
    margin-top: 2px;
  }
  .all-services-action-icons {
    gap: 5px;
  }
}
