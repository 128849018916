.add-portfolio-dropzone-size {
  width: 100%;
  height: 150px;
}


.add-events-dropzone-size {
  width: 100%;
  height: 150px;
  justify-content: flex-start;
}

.add-portfolio-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
