.add-review-col-row-2 {
  flex: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media all and (max-width: 850px) {
  .add-review-col-row-2 {
    flex-direction: column-reverse;
  }
  .add-review-company-order-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
