.smooth-scroll-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border: none;
  cursor: pointer;
  padding: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 25px;
  font-weight: 900;
  z-index: 10000;
}

@media all and (max-width: 1132px) {
  .smooth-scroll-button {
    display: none;
  }
}
