@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.logos {
  overflow: hidden;
  background: white;
  white-space: nowrap;
  position: relative;
  display: flex;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 30s slide infinite linear;
  white-space: nowrap;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
  filter: grayscale(100%);
}

.home-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media all and (max-width: 768px) {
  .logos-slide img {
    height: 70px;
    margin: 0 40px;
    filter: grayscale(100%);
  }
  
}


@media all and (max-width: 560px) {
  .logos-slide img {
    height: 55px;
    margin: 0 40px;
    filter: grayscale(100%);
  }
  
}
