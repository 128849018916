.dashboard-summary-container {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  padding: 26px 0px;
  align-items: center;
  border-radius: 20px;
  background: var(--white-100, #fff);
}

@media all and (max-width: 870px) {
  .dashboard-summary-container {
    overflow-x: auto;
    scrollbar-width: none;
    gap: 38px;
  }
  .dashboard-summary-icon-container {
    width: 50px;
    height: 50px;
    border-radius: 35%;
}
  .dashboard-summary-flex-item {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .dashboard-summary-flex-item:first-child {
    margin-left: 22px;
  }
  .dashboard-summary-flex-item:last-child {
    margin-right: 22px;
  }
  .dashboard-summary-icon-container {
    width: 60px;
    height: 40px;
  }
  .dashboard-header-component-container {
    display: none;
  }
  .dashboard-your-plan-container {
    display: none !important;
  }
}

@media all and (max-width: 600px) {
  .dashboard-summary-value {
    font-size: var(--mobile-font-17-px) !important;
  }
}
