.searchbar-dashboard-container {
  position: relative;
  font-size: 20px;
}
.searchbar-dashboard-icon {
  position: absolute;
  top: 25%;
  right: 10px;
}

.searchbar-dashboard-field {
  padding: 10px 40px 10px 20px;
  width: 16vw;
  border: 1px solid transparent;
  border-radius: 20px;
  box-sizing: border-box;
  outline: none;
}

.searchbar-dashboard-field:focus {
  border: 1px solid #555353;
}

@media all and (max-width: 1210px) {
  .searchbar-dashboard-field {
    width: 278px;
  }
}


@media all and (max-width: 532px) {
  .searchbar-dashboard-field {
    width: 184px;
  }
}
