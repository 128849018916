.all-blogs-tag-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

#blogs-page-all-blogs {
  padding-top: 75px;
}

.all-blogs-heading-mobile {
  display: none;
}

.all-blogs-close:hover {
  background-color: rgba(243, 196, 196, 0.507);
}
.all-blogs-tag {
  display: flex;
  /* width: 95px; */
  max-width: 100px;
  overflow: hidden;
  height: 10px;
  padding: 13px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid var(--black-60, #525252);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.13);
  background: white;
  cursor: pointer;
}

.all-blogs-tag-active {
  /* background: #f5af304d; */
  background: linear-gradient(0deg, #fff 0%, #fff 100%);
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.13); */
  border-radius: 24.522px;
  border: 1px solid #fff;
  backdrop-filter: blur(50px);
}

.all-blogs-blog-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  /* margin-top: 60px; */
  padding: 60px 100px 0px 100px;
  box-sizing: border-box;
}

.polygon2-all-blogs {
  position: absolute;
  right: 360px;
  margin-top: 50px;
  /* width: 100%; */
  /* overflow-y: visible; */
  /* overflow-x: hidden; */
  /* overflow: hidden; */
}

.all-blogs-landing-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 107px;
}

.all-blogs-landing-color {
  font-size: var(--font-90-px);
  color: #f5af30;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 73px;
  letter-spacing: 1px;
}

.all-blogs-landing-black {
  font-size: var(--font-90-px);
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 73px;
  letter-spacing: 1px;
}

.all-blogs-landing-heading-set {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.all-blogs-bottom-space {
  margin-bottom: 60px;
}

/* .all-blogs-search-box {
  margin-left: 5vw;
} */

.all-blogs-hover-animate-container {
  display: flex;
  gap: 10px;
}

@media all and (max-width: 1292px) {
  .all-blogs-landing-container {
    flex-direction: column;
    gap: 50px;
  }
  .all-blogs-search-box {
    margin-left: 0px;
  }
  /* .all-blogs-landing-break {
    display: none;
  } */
  .all-blogs-landing-heading-set {
    text-align: center;
  }
}

@media all and (max-width: 1200px) {
  .all-blogs-blog-container {
    grid-template-columns: 1fr 1fr;
    padding: 60px 60px 60px 60px;
  }
}

@media all and (max-width: 870px) {
  .all-blogs-blog-container {
    padding: 0px;
  }
  .blog-preview-main-heading {
    font-size: var(--font-20-px);
  }
}

@media all and (max-width: 764px) {
  .all-blogs-hover-animate-container {
    flex-direction: column;
  }

  .all-blogs-tag-container {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 20px 10px;
    scrollbar-width: none;
  }

  .all-blogs-tag {
    cursor: auto;
  }

  .all-blogs-blog-container {
    padding: 0px;
    margin-top: 40px;
  }
}

@media all and (max-width: 735px) {
  #blogs-page-all-blogs {
    padding-top: 30px;
  }
  .all-blogs-landing-container {
    margin-bottom: 28px;
  }
  .all-blogs-blog-container {
    grid-template-columns: 1fr;
    padding: 60px 60px 60px 60px;
  }
}

@media all and (max-width: 550px) {
  .all-blogs-search-box {
    display: flex;
    align-items: center;
  }
  .blog-preview-container {
    /* margin-left: -25px; */
    /* margin-right: -25px; */
    gap: 9px;
  }
  .all-blogs-tag-container {
    gap: 17px;
  }
  .all-blogs-tag {
    padding: 17px 25px;
    border: 1px solid var(--black-60, #525252);
    cursor: default;
    color: #525252;
    font-family: Epilogue;
    font-size: var(--mobile-font-16-px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .all-blogs-tag-active {
    background-color: #f4f4f6;
    padding: 17px 25px;
    border: 1px solid black;
    cursor: default;
    color: #000;
    font-family: Epilogue;
    font-size: var(--mobile-font-16-px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .all-blogs-blog-container {
    padding: 0px;
    margin-top: 0px;
  }
  .all-blogs-heading-mobile {
    display: block;
    color: #f16a23;
    text-align: center;
    font-family: Poppins;
    font-size: var(--mobile-font-24-px);
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 250% */
  }
  .all-blogs-landing-container {
    flex-direction: column;
    gap: 60px;
  }
  .all-blogs-landing-black,
  .all-blogs-landing-color {
    font-size: var(--mobile-font-30-px);
    line-height: 42px;
  }

  .all-blogs-tag {
    cursor: auto;
    font-size: var(--mobile-font-16-px);
  }

  .all-blogs-whole-heading {
    text-align: left;
  }

  .all-blogs-landing-heading-set {
    align-items: flex-start;
  }

  .all-blogs-landing-heading-set {
    text-align: left;
    align-self: flex-start;
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1920px) {
  .all-blogs-landing-color,
  .all-blogs-landing-black {
    line-height: 90px;
  }
}
