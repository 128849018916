.hover-animate-image {
  height: 400px;
  width: 150px;
  object-fit: cover;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.hover-animate-image-container:hover .hover-animate-image {
  width: 250px;
}

.hover-animate-image-container {
  position: relative;
}

.hover-animate-text {
  color: var(--white-color);
  font-weight: 500;
  font-size: var(--font-24-px);
}

.hover-animate-topic-count {
  display: none;
  transition: all 0.7s ease-out;
  font-weight: 500;
  font-size: var(--font-24-px);
  color: var(--white-color);
}

.hover-animate-topic-text {
  font-size: var(--font-18-px);
}

.hover-animate-text-set {
  transform: rotate(-90deg);
  transition: all 0.7s ease;
  display: flex;
  gap: 50px;
  position: absolute;
  bottom: 50px;
  left: 35px;
}

.hover-animate-image-container:hover .hover-animate-text-set {
  transform: rotate(0deg);
}
.hover-animate-image-container:hover .hover-animate-topic-count {
  display: block;
}


@media all and (max-width: 764px) {
  .hover-animate-image {
    height: 300px;
    width: 90vw;
    object-fit: cover;
    border-radius: 20px;
    transition: all 0.3s ease;
  }

  .hover-animate-text-set {
    transform: rotate(0deg);
    transition: all 0.7s ease;
    display: flex;
    gap: 50px;
    position: absolute;
    bottom: 50px;
    left: 35px;
  }
}
