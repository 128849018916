.about-people-view-main-container {
  box-sizing: border-box;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}
.about-people-view-image {
  box-sizing: border-box;
  position: relative;
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
}

.about-people-avatar {
  /* width: calc(80vw / 4); */
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.about-people-social-media {
  display: none;
  height: 100%;
  position: absolute;
  top: 0px;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  opacity: 0;
}

.about-people-one-social-media {
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  font-size: var(--font-30-px);
}

.about-people-one-social-media:hover {
  background-color: var(--secondary-color);
  color: white;
}

.about-people-view-image:hover .about-people-social-media {
  display: flex;
  opacity: 1;
  transition: opacity 2s linear;
}

.about-people-view-name {
  color: var(--black-color);
  font-family: "Work Sans";
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 500;
  line-height: 123.6%;
}

.about-people-view-position {
  color: var(--gray-paragraph, #7b7b7b);
  font-family: "Work Sans";
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 400;
  line-height: 150.6%;
}

.about-people-view-detail {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}
