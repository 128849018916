:root {
  /* Colors */
  --primary-color: #f5b02f;
  --secondary-color: #f16a23;
  --background-color: #f4f4f4;
  --text-color: #333;
  --caret-down-color: #999;
  --description-text-color: #646464;
  --black-color: black;
  --white-color: white;
  /* Specific Fonts */
  --font-12-8-px: 12.8px;
  --font-14-px: 14px;
  --font-16-px: 16px;
  --font-18-px: 18px;
  --font-20-px: 20px;
  --font-22-px: 22px;
  --font-24-px: 24px;
  --font-25-px: 25px;
  --font-30-px: 30px;
  --font-32-px: 32px;
  --font-36-px: 36px;
  --font-40-px: 40px;
  --font-45-px: 45px;
  --font-48-px: 48px;
  --font-65-px: 65px;
  --font-90-px: 90px;
  /* Font Size Mobile */
  --mobile-font-10-px: 10px;
  --mobile-font-12-px: 12px;
  --mobile-font-12-8-px: 12.8px;
  --mobile-font-14-px: 14px;
  --mobile-font-15-px: 15px;
  --mobile-font-16-px: 16px;
  --mobile-font-17-px: 17px;
  --mobile-font-18-px: 18px;
  --mobile-font-20-px: 20px;
  --mobile-font-24-px: 24px;
  --mobile-font-25-px: 25px;
  --mobile-font-28-px: 28px;
  --mobile-font-30-px: 30px;
  --mobile-font-40-px: 40px;
  /* Screen */
  --mobile-width: 550px;
}

@media screen and (min-width: 0px) and (max-width: 550px) {
  :root {
    --mobile-font-12-px: 12px;
    --mobile-font-12-8-px: 12.8px;
    --mobile-font-13-px: 13px;
    --mobile-font-14-px: 14px;
    --mobile-font-15-px: 15px;
    --mobile-font-16-px: 16px;
    --mobile-font-17-px: 17px;
    --mobile-font-18-px: 18px;
    --mobile-font-20-px: 20px;
    --mobile-font-24-px: 24px;
    --mobile-font-25-px: 25px;
    --mobile-font-28-px: 28px;
    --mobile-font-30-px: 30px;
    --mobile-font-40-px: 40px;

    --font-12-8-px: 13px;
    --font-14-px: 14px;
    --font-16-px: 14px;
    --font-18-px: 15px;
    --font-20-px: 20px;
    --font-22-px: 18px;
    --font-24-px: 20px;
    --font-25-px: 25px;
    --font-30-px: 18px;
    --font-32-px: 25px;
    --font-40-px: 30px;
    --font-45-px: 30px;
    --font-48-px: 35px;
    --font-90-px: 50px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 768px) {
  :root {
    --font-12-8-px: 12.8px;
    --font-14-px: 14px;
    --font-16-px: 16px;
    --font-18-px: 18px;
    --font-20-px: 20px;
    --font-22-px: 18px;
    --font-24-px: 20px;
    --font-25-px: 20px;
    --font-30-px: 20px;
    --font-32-px: 23px;
    --font-36-px: 24px;
    --font-40-px: 25px;
    --font-45-px: 26px;
    --font-48-px: 30px;
    --font-65-px: 35px;
    --font-90-px: 50px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1279px) {
  :root {
    --font-12-8-px: 14px;
    --font-14-px: 14px;
    --font-16-px: 16px;
    --font-18-px: 18px;
    --font-20-px: 20px;
    --font-22-px: 17px;
    --font-24-px: 22px;
    --font-25-px: 20px;
    --font-30-px: 25px;
    --font-36-px: 24px;
    --font-32-px: 25px;
    --font-40-px: 30px;
    --font-45-px: 32px;
    --font-48-px: 40px;
    --font-65-px: 44px;
    --font-90-px: 64px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  :root {
    --font-12-8-px: 15px;
    --font-14-px: 14px;
    --font-16-px: 16px;
    --font-18-px: 18px;
    --font-20-px: 17px;
    --font-22-px: 18px;
    --font-24-px: 19px;
    --font-25-px: 20px;
    --font-30-px: 21px;
    --font-32-px: 21px;
    --font-36-px: 25px;
    --font-40-px: 30px;
    --font-45-px: 32px;
    --font-48-px: 40px;
    --font-65-px: 45px;
    --font-90-px: 60px;
  }
}

@media screen and (min-width: 1920px) {
  /* :root {
    --font-12-8-px: 18.8px;
    --font-14-px: 15px;
    --font-16-px: 15px;
    --font-18-px: 22px;
    --font-20-px: 20px;
    --font-22-px: 21px;
    --font-24-px: 24px;
    --font-25-px: 25px;
    --font-30-px: 25px;
    --font-32-px: 32px;
    --font-36-px: 33px;
    --font-40-px: 35px;
    --font-45-px: 37px;
    --font-48-px: 45px;
    --font-65-px: 65px;
    --font-90-px: 65px;
  } */
  :root {
    --font-12-8-px: 15.8px;
    --font-14-px: 15px;
    --font-16-px: 15px;
    --font-18-px: 17px;
    --font-20-px: 17px;
    --font-22-px: 18px;
    --font-24-px: 22px;
    --font-25-px: 21px;
    --font-30-px: 24px;
    --font-32-px: 21px;
    --font-36-px: 33px;
    --font-40-px: 34px;
    --font-45-px: 30px;
    --font-48-px: 37px;
    --font-65-px: 65px;
    --font-90-px: 80px;
}
}
