.careers-page-career-heading-dark {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-40-px);
  line-height: 16px;
}
.careers-page-career-heading-color {
  color: var(--secondary-color);
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-40-px);
  line-height: 16px;
}

.careers-page-career-heading-container {
  margin-top: 150px;
  text-align: center;
  margin-bottom: 50px;
}

.careers-page-career-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  padding: 10px 100px 10px 100px;
  margin-bottom: 70px;
}

@media all and (max-width: 1200px) {
  .careers-page-career-items {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (max-width: 896px) {
  .careers-page-career-items {
    grid-template-columns: 1fr;
  }

  .careers-page-polygon {
    display: none;
  }
}

@media all and (max-width: 633px) {
  .careers-page-career-items {
    padding: 10px 40px 10px 40px;
  }
}

@media all and (max-width: 550px) {
  .careers-page-career-items {
    padding: 10px 20px 10px 20px;
  }
  .careers-page-career-heading-container {
    margin-top: 57px;
    text-align: center;
    margin-bottom: 23px;
  }
  .careers-page-career-heading-color,
  .careers-page-career-heading-dark {
    font-size: var(--font-25-px);
  }
  .careers-page-career-items {
    gap: 29px;
  }
}

@media all and (max-width: 400px) {
  .careers-page-career-items {
    padding: 10px 0px 10px 0px;
  }
}
