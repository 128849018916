.contactus-form-container {
  background: #101010;
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media all and (max-width: 1500px) {
}

.contact-us-form-flex {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 80px;
  max-width: 1800px;
  gap: 103px;
}

.contact-us-form-heading {
  font-size: var(--font-65-px);
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contact-us-form-heading-white {
  color: var(--White, var(--white-100, #fff));
}

.contact-us-form-heading-yellow {
  color: var(--white-100, #ffdf74);
}
.contact-us-form-description {
  color: var(--White, var(--white-100, #fff));
  font-family: Poppins;
  font-size: var(--font-25-px);
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.223% */
}

@media all and (min-width: 1920px) {
  .contact-us-form-description {
    line-height: 39px;
  }
}

.contact-us-form {
  --width-of-input: 250px;
  --border-height: 1px;
  --border-before-color: rgb(255, 255, 255);
  --border-after-color: #5891ff;
  --input-hovered-color: #4985e01f;
  position: relative;
  width: var(--width-of-input);
}
/* styling of Input */
.cotact-us-input {
  color: #fff;
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
  border-bottom: var(--border-height) solid var(--border-before-color);
}
.cotact-us-input::placeholder {
  color: #fff;
}
/* styling of animated border */
.input-border {
  position: absolute;
  background: var(--border-after-color);
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 0;
  transition: 0.3s;
}
/* Hover on Input */
.cotact-us-input:hover {
  background: var(--input-hovered-color);
}

.cotact-us-input:focus {
  outline: none;
}

.contact-us-form-row {
  display: flex;
  gap: 16px;
}

.contact-us-form-row-collection {
  display: flex;
  gap: 27px;
  flex-direction: column;
}

.contact-us-form-submit {
  margin-top: 10px;
  border-radius: 10px;
  background: #f16a23;
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-16-px);
  padding: 5px 28px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.38px;
  width: 100%;
  height: 42px;
}

@media all and (max-width: 1136px) {
  .contact-us-form-flex {
    margin-bottom: 0px;
  }
  .contact-us-form-flex {
    flex-direction: column;
    gap: 50px;
  }
  .contact-us-form-heading {
    text-align: center;
  }
  .contact-us-form-description {
    margin-top: 20px;
    text-align: center;
  }
  .contact-us-form-description br {
    display: none;
  }
  .contact-us-form-submit {
    width: 100%;
  }
}

@media all and (max-width: 660px) {
  .contact-us-form-row {
    flex-direction: column;
  }
  .cotact-us-input {
    width: 80vw;
  }
  /* .contact-us-landing-image-container {
    display: none;
  } */
  .contact-us-form-heading {
    font-size: var(--mobile-font-30-px);
  }
  .contact-us-form-description {
    font-size: var(--mobile-font-20-px);
    line-height: 33px;
  }
  .contactus-form-container {
    margin-top: 0px;
  }
}

@media all and (max-width: 580px) {
  .contact-us-form-flex {
    padding: 50px 40px;
  }
}

@media all and (max-width: 550px) {
  .contact-us-form-submit {
    padding: 6px 28px;
    width: 100%;
  }
  .contact-us-button {
    align-self: center;
    width: 100%;
  }
}

@media all and (max-width: 400px) {
  .contact-us-form-flex {
    padding: 50px 20px;
  }
}
