.career-card-main-container {
  padding: 40px 40px 40px 40px;
  border-radius: 10px;
  background: var(--white-100, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.career-card-header {
  color: #4a4a4a;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-25-px);
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.career-card-image {
  width: 120px;
}

.career-card-apply-button {
  border-radius: 50px;
  background: var(--secondary-color);
  cursor: pointer;
  padding: 10px 50px 10px 50px;
}

.career-card-apply-button button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: var(--white-color);
  color: var(--white-100, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.career-card-see-detail {
  color: #4a4a4a;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
}

.career-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 39px;
}

@media all and (max-width: 550px) {
  .career-card-header {
    font-size: var(--mobile-font-18-px);
  }

  .career-card-apply-button button {
    font-size: var(--mobile-font-14-px);
  }

  .career-card-see-detail {
    font-size: var(--mobile-font-14-px);
  }
}
