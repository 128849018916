#drop_zone {
  display: flex;
  /* height: 50vh; */
  /* margin: auto; */
  /* padding: auto; */
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 10px; */
  border-radius: 5px;
  border: 2px dashed #c1bbeb;
  background: var(--white-100, #fff);
  box-sizing: border-box;
  position: relative;
}

.drag-and-drop-image-container {
  /* padding: 10px; */
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  position: relative;
}

.image-preview img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  object-fit: cover;
  max-width: 300px;
  max-height: 130px;
}

.close-button {
  position: absolute;
  right: 0;
  color: var(--primary-color);
}

.drag-and-drop-text {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px;
  color: #a098ae;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
}

@media all and (max-width: 550px) {
  .drag-and-drop-text {
    font-size: var(--mobile-font-12-px);
  }
}
