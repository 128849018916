.db-client-overlay {
  /* opacity: 0;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transition: opacity 1s, visibility 1s; */
  z-index: 490;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.377);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
}

.db-client-overlay-active {
  opacity: 1;
  /* target opacity */
  visibility: visible;
  /* target visibility */
}

.db-client-popup-container {
  width: 500px;
  border-radius: 20px;
  margin-left: 320px;
}

.db-client-popup-header {
  padding: 10px 0px 10px 10px;
  background-color: black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.db-client-popup-body {
  padding: 20px 20px 20px 20px;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.db-client-popup-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.db-client-popup-heading {
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-24-px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media all and (max-width: 1210px) {
  .db-client-popup-container {
    margin-left: 0px;
  }
}
