.dashboard-summary-flex-item {
  display: flex;
  align-items: center;
  gap: 27px;
}

.dashboard-summary-icon-container {
  width: 70px;
  height: 50px;
  border-radius: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.dashboard-summary-icon-service {
  background: #000000;
}

.dashboard-summary-icon-career {
  background: #fb7d5b;
}

.dashboard-summary-icon-events {
  background: #fcc43e;
}

.dashboard-summary-icon-blog {
  background: #303972;
}

.dashboard-summary-svg-icon-style {
  width: 30px;
  height: 30px;
}

.dashboard-summary-heading {
  color: #a098ae;
  font-family: Poppins;
  font-size: var(--font-18-px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-summary-value {
  color: #303972;
  font-family: Poppins;
  font-size: var(--font-36-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard-summary-data {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media all and (max-width: 1100px) {
  .dashboard-summary-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }
  .dashboard-summary-icon-container {
    width: 46px;
    height: 39px;
  }
  .dashboard-summary-svg-icon-style {
    width: 20px;
    height: 20px;
  }
  .dashboard-summary-flex-item:first-child {
    margin-left: 0px;
  }
  .dashboard-summary-flex-item {
    gap: 10px;
  }
  .dashboard-summary-data {
    gap: 0px;
  }
  .dashboard-summary-container {
    gap: 20px;
  }
}
