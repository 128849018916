.circle1 {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: #ffdf74;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: -139px;
  margin-left: -170px;
  clip-path: inset(78px 0 0 71px);
}

.circle2 {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #ffdf74;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* margin-top: 266px; */
  margin-left: -70px;
  clip-path: inset(0px 0 75px 0px);
  z-index: 10;
  /* margin-bottom: 0px; */
  bottom: -74px;
}

.circle3 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 120px;
  margin-left: -180px;
  clip-path: inset(0px 0 0 0px);
  z-index: 10;
}

.testimonial-view-person-image {
  padding: 60px 20px 0px 100px;
  position: relative;
}

.testimonial-view-container {
  background: #f9fafc;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

@media all and (min-width: 1750px) {
  .testimonial-view-container {
    width: 100%;
    max-width: 1800px;
  }
}

.testimonial-person-image {
  height: 415px;
  position: relative;
  z-index: 21;
  bottom: -6px;
}

.testimonial-view-person-name {
  color: var(--Text-Gray-900, #18191f);
  font-feature-settings: "salt" on, "liga" off;
  font-family: Inter;
  font-size: var(--font-40-px);
  font-style: normal;
  font-weight: 800;
  line-height: 54px;
}

.testimonial-view-person-position {
  color: var(--Text-Gray-900, #18191f);
  font-feature-settings: "salt" on, "liga" off;
  font-family: Inter;
  font-size: var(--font-25-px);
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.testimonial-view-person-review {
  margin-top: 30px;
  color: #18191f;
  font-feature-settings: "salt" on, "liga" off;
  font-family: Inter;
  font-size: var(--font-24-px);
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
  padding-right: 80px;
}

.testimonial-quotes {
  position: absolute;
  margin-top: 95px;
  margin-left: -45px;
}

@media all and (max-width: 1240px) {
  .testimonial-view-person-review {
    padding-right: 20px;
  }
}

@media all and (max-width: 1045px) {
  .testimonial-view-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .testimonial-view-person-name {
    text-align: center;
  }
  .testimonial-view-person-position {
    text-align: center;
  }
  .testimonial-view-person-review {
    padding: 20px;
    margin-top: 0px;
  }
  .circle1 {
    clip-path: inset(90px 0 0 0px);
  }
  .circle2 {
    display: none;
  }
  .testimonial-view-person-image {
    padding: 0px;
    padding-top: 20px;
  }
}

@media all and (max-width: 1045px) {
  .circle1 {
    display: none;
  }
}
