.portfolio-landing-heading-set {
  margin-left: 35px;
}
.portfolio-landing-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: -1;
  background: linear-gradient(
    90deg,
    #ffffff,
    #ffffff,
    #fffffff5,
    #ffffff8c,
    transparent
  );
}

.portfolio-landing-image {
  width: 60vw;
  z-index: -1;
  height: 80vh;
  object-fit: cover;
  border-radius: 20px;
}

.portfolio-landing-text-dark {
  font-size: var(--font-90-px);
  color: var(--black-70, #3d3d3d);
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 60px; /* 101.111% */
}

.portfolio-landing-text-color {
  font-size: var(--font-90-px);
  color: #f5af30;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
}

.portfolio-landing-description {
  color: #62ae6e;
  font-family: Poppins;
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 19px;
}

@media all and (max-width: 1160px) {
  #portfolio-landing {
    padding: 0px;
    height: 600px;
    /* margin-top: 12px; */
  }
  .portfolio-landing-text-dark {
    color: #ffffff;
  }
  .portfolio-landing-description {
    color: #ffffff;
  }
  .portfolio-landing-heading-set {
    margin-left: 30px;
    margin-right: 30px;
  }
  .portfolio-landing-container {
    /* position: relative; */
    width: 100%;
    /* border-radius: 20px; */
    background: rgb(67 67 67 / 36%);
    height: 100%;
  }
  .portfolio-landing-image {
    height: 600px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    border-radius: 0px;
  }
}

@media all and (max-width: 550px) {
  .portfolio-landing-text-dark {
    color: var(--white-100, #fff);
    font-family: Poppins;
    font-size: var(--mobile-font-40-px);
    font-style: normal;
    font-weight: 700;
    line-height: 48.096px;
  }
  .portfolio-landing-text-color {
    color: var(--white-100, #f5af30);
    font-family: Poppins;
    font-size: var(--mobile-font-40-px);
    font-style: normal;
    font-weight: 700;
    line-height: 48.096px;
  }
  .portfolio-landing-description {
    color: var(--white-100, #fff);
    font-family: Epilogue;
    font-size: var(--mobile-font-14-px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #portfolio-landing {
    height: calc(85vh - 61.98px);
  }
  .portfolio-landing-image {
    height: calc(85vh - 61.98px);
  }
}

@media screen and (min-width: 1920px) {
  .portfolio-landing-image {
    width: 60vw;
    z-index: -1;
    height: 50vh;
    object-fit: cover;
    border-radius: 20px;
  }
  .portfolio-landing-text-dark,
  .portfolio-landing-text-color {
    line-height: 94px;
  }
}
