.dashboard-sidebar-nav {
  background-color: black;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  z-index: 10000;
  overflow-y: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-sidebar-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-sidebar-image {
  width: 200px;
  height: auto;
  margin-top: 45px;
  margin-right: 50px;
}

.sidebar-menu {
  color: #c1bbeb;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 0px 15px 20px;
  font-family: Poppins;
  font-size: var(--font-18-px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.5s color ease;
}

.sidebar-menu:hover {
  border-radius: 40px 0px 0px 40px;
  background: #f3f4ff;
  color: black;
  cursor: pointer;
}

.sidebar-menu-active {
  border-radius: 40px 0px 0px 40px;
  background: #f3f4ff;
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.sidebar-svg-icon {
  width: 25px;
  height: 25px;
}

.dashboard-sidebar-menu-container a {
  text-decoration: none;
}

.dashboard-sidebar-menu-container {
  margin-top: 50px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.side-bar-info-line-1 {
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.side-bar-info-line-2 {
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-sidebar-info-container {
  margin: 50px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.sidebar-close-menu {
  color: white;
  font-size: var(--font-25-px);
  margin-top: 20px;
  cursor: pointer;
  display: none;
}

.sidebar-close-menu:hover {
  color: var(--primary-color);
}

.hamburger-menu-sidebar {
  position: absolute;
  font-size: var(--font-25-px);
  top: 32px;
  left: 20px;
  display: none;
}
/* Hide Sidebar in Small Screen */
@media all and (max-width: 1210px) {
  .sidebar-close-menu {
    display: block;
  }
  .dashboard-sidebar-nav {
    display: none;
  }
  .hamburger-menu-sidebar {
    display: block;
  }
  .active-sidebar {
    display: block;
  }
}

@media all and (max-width: 550px) {
  .sidebar-menu {
    font-size: var(--mobile-font-14-px);
  }
  .dashboard-sidebar-menu-container {
    gap: 13px;
  }
  .dashboard-sidebar-image {
    width: 162px;
    height: auto;
    margin-top: 21px;
    margin-right: 50px;
  }
  .sidebar-close-menu {
    margin-top: 36px;
    cursor: default;
  }
  .dashboard-sidebar-menu-container {
    margin-top: 33px;
  }
  .sidebar-svg-icon {
    width: 20px;
    height: 20px;
  }
}
