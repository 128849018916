.search-box-input-field {
  width: 60%;
  padding: 12px 15px;
  font-size: var(--font-20-px);
  border: none;
  border-radius: 15px 0px 0px 15px;
  box-shadow: 0px 3.188px 39.844px 0px rgba(0, 0, 0, 0.2);
  background: var(--white-100, #fff);
  outline: none;
}

.search-box-input-field::placeholder {
  color: #000;
  font-family: Poppins;
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.search-box-button {
  padding: 11.9px 15px;
  font-size: var(--font-18-px);
  border-radius: 0px 15px 15px 0px;
  background: #f5af30;
  box-shadow: 0px 3.188px 39.844px 0px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
}

@media all and (max-width: 550px) {
  .search-box-input-field {
    font-size: var(--mobile-font-15-px);
    padding: 8px 15px;
  }
  .search-box-input-field::placeholder {
    font-size: var(--mobile-font-15-px);
  }
  .search-box-button {
    font-size: var(--mobile-font-14-px);
    padding: 8.9px 15px;
  }
}
