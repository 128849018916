.about-people-dark-text {
  font-size: var(--font-48-px);
  color: var(--Black, #373737);
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 123.6%; /* 59.328px */
}

/* color: #000;
text-align: center;
font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: 22px; 88% */

.about-people-secondary-text {
  font-size: var(--font-48-px);
  color: var(--Orange, #ff5300);
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 123.6%;
}

.about-people-main-heading {
  text-align: center;
}

.about-people-photo-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  margin-bottom: 60px;
  box-sizing: border-box;
}

@media all and (max-width: 1024px) {
  .about-people-photo-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media all and (max-width: 750px) {
  .about-people-photo-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (max-width: 650px) {
  .about-people-photo-grid {
    grid-template-columns: 1fr;
    padding: 0px 11px 0px 11px;
  }
}

@media all and (max-width: 550px) {
  .about-people-dark-text,
  .about-people-secondary-text {
    text-align: center;
    font-family: Poppins;
    font-size: var(--mobile-font-25-px);
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
  .about-people-view-name {
    font-size: var(--mobile-font-20-px);
  }

  .about-people-dark-text {
    color: black;
  }

  .about-people-view-position {
    font-size: var(--mobile-font-16-px);
  }
}
