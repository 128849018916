.benefits-main-container {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  gap: 50px;
}

.benefit-line {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 40px;
  box-sizing: border-box;
}

.benefit {
  display: flex;
  gap: 30px;
  align-items: center;
  flex: 0 0 45%;
}

.benefits-color-heading {
  font-size: var(--font-48-px);
  color: var(--secondary-color);
  font-feature-settings: "salt" on, "liga" off;
  font-weight: 800;
  line-height: 64px;
}

.benefits-contd-heading {
  font-size: var(--font-48-px);
  color: var(--black-color);
  font-feature-settings: "salt" on, "liga" off;
  font-weight: 800;
  line-height: 64px;
}

.single-benefit-heading {
  color: #18191f;
  font-feature-settings: "salt" on, "liga" off;
  font-family: Inter;
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
@media all and (max-width:1100px){
  .single-benefit-heading {
    line-height: 40px;
  }
}

.single-benefit-description {
  color: #18191f;
  font-feature-settings: "salt" on, "liga" off;
  font-family: Inter;
  font-size: var(--font-22-px);
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 118.182% */
}

.benefits-icon-container {
  width: 50px;
  height: 50px;
  /* background-color: #f4f5f7; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefits-image {
  width: auto;
  height: 508px;
}

.benefits-heading-tab {
  display: none;
}

@media all and (max-width: 1490px) {
  .benefits-heading-tab {
    display: block;
    margin-bottom: 30px;
  }
  .benefits-heading-big {
    display: none;
  }

  .benefits-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .benefits-heading-main {
    text-align: center;
  }
}

@media all and (max-width: 837px) {
  .benefit-line {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .benefits-image {
    width: 60vw;
    height: auto;
  }
}

@media all and (max-width: 550px) {
  .benefits-main-container {
    gap: 65px;
  }
  .benefits-image {
    width: 82vw;
    height: auto;
  }
  .single-benefit-heading {
    font-size: var(--mobile-font-20-px);
    line-height: 40px;
    text-align: center;
  }
  .benefits-heading-tab {
    display: none;
  }
  .benefits-heading-big {
    display: block;
  }
  .benefits-color-heading,
  .benefits-contd-heading {
    font-size: var(--mobile-font-25-px);
    line-height: 39px;
  }

  .benefit-line {
    margin-top: 23px;
    border-radius: 35.386px;
    background: var(--white-100, #fff);
    /* box-shadow: 0px 5.662px 14.154px 0px rgba(0, 0, 0, 0.13); */
    padding: 34px 32px 0px 32px;
    /* margin-bottom: 70px; */
  }

  .benefit {
    flex-direction: column;
    /* align-items: flex-start; */
    margin-bottom: 20px;
    gap: 6px;
    /* justify-content: center; */
    align-items: center;
  }

  .single-benefit-description {
    font-size: var(--mobile-font-14-px);
    margin-top: 6px;
    font-family: Epilogue;
    text-align: justify;
  }
}

@media screen and (min-width: 1920px) {
  .single-benefit-heading {
    line-height: 45px;
  }
  .single-benefit-description {
    line-height: 30px;
}
}
