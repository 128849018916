.dashboard-your-plan-container {
  padding: 50px 30px;
  border-radius: 20px;
  background: linear-gradient(179deg, #000 -47.98%, #303972 163.51%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0px 20px 50px 0px rgba(191, 21, 108, 0.05);
  height: 100%;
  box-sizing: border-box;
  color: var(--white-color);
  position: relative;
  /* overflow: hidden; */
}

.vector-your-plan {
  position: absolute;
  bottom: 61px;
  right: 8.4px;
  width: 80%;
  /* height: 111%; */
  margin-bottom: -80px;
  clip-path: inset(0 15.6px -4 30);
}

@media all and (max-width: 1100px) {
  .dashboard-your-plan-container {
    display: none;
  }
}
