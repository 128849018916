.testimonial-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.polygon2-testimonial {
  position: absolute;
  right: 600px;
  margin-top: -350px;
}

.testimonial-container-mobile {
  display: none;
}

@media all and (max-width: 1300px) {
  .polygon-testimonial-2,
  .polygon-testimonial-1 {
    display: none;
  }
}

@media all and (max-width: 550px) {
  .testimonial-container {
    display: none;
  }
  .testimonial-container-mobile {
    display: block;
  }
  #home-testimonial {
    /* padding: 20px; */
    padding-bottom: 51px;
    background: #fff7da;
  }

  .testimonial-container-mobile-main-heading {
    font-size: var(--mobile-font-25-px);
    text-align: center;
    margin-top: 9px;
  }

  .testimonial-container-mobile-heading-1 {
    color: var(--gray-paragraph, #7b7b7b);
    text-align: center;
    text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    font-family: Poppins;
    font-size: var(--mobile-font-16-px);
    font-style: normal;
    font-weight: 500;
    line-height: 150.6%; /* 24.096px */
  }

  .testimonial-container-mobile-main-heading-dark {
    color: var(--Black, #373737);
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 123.6%; /* 30.9px */
  }

  .testimonial-container-mobile-main-heading-color {
    color: #f16a23;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 123.6%;
  }

  .testimonial-mobile-card {
    overflow: hidden;
    width: 77vw;
    height: 200px;
    border-radius: 20px;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    gap: 26px;
  }

  .testimonial-cards-mobile {
    overflow-x: auto;
    display: grid;
    gap: 20px;
    padding-top: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 20px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
  }

  .testimonial-cards-mobile:first-child {
    margin-left: 20px;
  }

  .testimonial-mobile-card-iamge {
    height: 97px;
    margin-left: -10px;
  }

  .testimonial-mobile-card-iamge-and-name {
    display: flex;
    gap: 5px;
  }

  .testimonial-mobile-card-desc {
    text-align: justify;
    color: rgba(0, 0, 0, 0.59);
    font-family: Poppins;
    font-size: var(--mobile-font-13-px);
    font-style: normal;
    font-weight: 400;
    line-height: 126%; /* 15.12px */
  }

  .testimonial-mobile-card-name {
    margin-top: 10px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: var(--mobile-font-16-px);
    font-style: normal;
    font-weight: 400;
    line-height: 150.6%;
    text-align: left;
  }

  .testimonial-mobile-card-position {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: var(--mobile-font-10-px);
    font-style: normal;
    font-weight: 400;
    line-height: 123.6%;
    text-transform: capitalize;
    margin-left: 6px;
  }

  .in-view {
    background: red;
  }
}
