.carrier-brief-container {
  margin: 198px 80px 20px 80px;
}

.carrier-brief-heading-container {
  text-align: center;
  margin-bottom: 50px;
}

.carrier-brief-heading {
  font-size: var(--font-48-px);
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.carrier-desc-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 90px;
}

.carrier-desc-container-sections {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}
.carrier-desc-form-submission {
  flex: 1;
}

.career-brief-desc-section {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.career-brief-desc-section-heading {
  /* color: #000;
  font-family: Poppins;
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 400;
  line-height: 30px; */

  color: #000;
  font-family: Poppins;
  font-size: var(--font-24-px);
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
}

.carrer-submit-form {
  padding: 30px 46px 30px 46px;
  border-radius: 20px;
  border: 0.5px solid #9f9f9f6b;
  background: var(--white-100, #fff);
}

.carrer-submit-form-heading {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 53.333% */
  margin-top: 7px;
  margin-bottom: 30px;
}

.form-label {
  display: block;
  color: #000;
  font-family: Poppins;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
}
.form-controller {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #9f9f9f6b;
  box-sizing: border-box;
  margin-top: 6px;
}
.file-path{
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .career-brief-file-container {
  margin-top: 5px;
  border: 1px solid grey;
}

input[type="file"]::file-selector-button {
  background-color: #fff;
  color: #000;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  padding: 10px 15px;
  margin-right: 20px;
  transition: 0.5s;
}

input[type="file"]::file-selector-button:hover {
  background-color: #eee;
  border: 0px;
  border-right: 1px solid #e5e5e5;
} */

.career-brief-submit {
  border-radius: 10px;
  border: 0.5px solid #9f9f9f;
  background: #f16a23;
  width: 100%;
  margin-top: 10px;
  border: none;
  padding: 16px 0px;
  cursor: pointer;
  color: var(--white-color);
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-18-px);
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 88.889% */
}

.career-brief-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.career-brief-list {
  margin-inline-start: -25px;
}

.career-brief-list li {
  list-style-type: "- ";
}

[type="file"]::-webkit-file-upload-button {
  /* background: #ED1C1B; */
  /* border: 2px solid #ED1C1B; */
  /* border-radius: 4px; */
  /* color: #fff; */
  cursor: pointer;
  font-size: 12px;
  outline: none;
  /* padding: 10px 25px; */
  text-transform: uppercase;
  /* transition: all 1s ease; */
}
.carrier-brief-container .file-input {
  display: none;
}

.carrier-brief-container .file-label {
  display: block;
  background: #E5E5E5;
  width: 109px;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.carrier-brief-container .input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media all and (max-width: 1152px) {
  .carrier-desc-container {
    flex-direction: column;
    gap: 50px;
  }
  .carrier-desc-form-submission {
    width: 100%;
  }
  .career-brief-polygon {
    display: none;
  }
}

@media all and (max-width: 780px) {
  .carrier-brief-container {
    margin: 100px 5px 20px 5px;
  }
}

@media all and (max-width: 550px) {
  .carrier-brief-heading {
    font-size: var(--mobile-font-30-px);
    line-height: normal;
  }
  .carrier-brief-heading-container {
    margin-bottom: 32px;
  }
  .career-brief-desc-section-heading {
    font-size: var(--mobile-font-20-px);
  }
  .carrer-submit-form-heading {
    display: none;
  }

  .carrer-submit-form {
    padding: 30px 5px 30px 5px;
    border-radius: 0px;
    border: none;
    background: none;
  }

  .career-brief-form {
    display: flex;
    flex-direction: column;
    gap: 23px;
  }

  .career-brief-form textarea {
    height: 40px;
  }

  .form-controller {
    color: #000000;
    font-size: 0.9rem;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
    border-radius: 0px;
    margin-top: 0px;
    border-bottom: 1px solid black;
  }

  .career-brief-submit {
    border: 0.5px solid #9f9f9f;
    width: 100%;
    font-size: var(--mobile-font-15-px);
    padding: 15px 0px;
  }

  .career-brief-submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* 
.form-controller {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #9f9f9f6b;
  box-sizing: border-box;
  margin-top: 6px;
} */

.grow-wrap {
  display: grid;
}
.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  padding: 0.5rem;
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}
