.footer-container {
  width: 100%;
  background: #101010;
  color: white;
  padding-top: 50px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.footer-copyright-mobile {
  display: none;
}

@media all and (min-width: 1750px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-mail-details {
    display: flex;
    justify-content: center;
    max-width: 1675px;
  }
}

.footer-container p {
  text-align: center;
}

.footer-mail-details {
  display: flex;
  justify-content: center;
}

.footer-about-company {
  padding: 0px 40px 0px 40px;
}

.footer-company-name {
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-40-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer-address-mobile {
  display: none;
}

.footer-company-description {
  margin-top: 20px;
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-22-px);
  font-style: normal;
  font-weight: 400;
  line-height: 128%; /* 38.4px */
  letter-spacing: 0.6px;
}

.footer-details-heading {
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer-details-menu {
  margin-top: 10px;
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 214.286% */
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer-address {
  width: 200px;
}

.footer-contact-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-info-mobile {
  display: none;
  flex-direction: column;
  gap: 17px;
  align-items: center;
  justify-content: center;
}

.footer-social-media {
  display: flex;
  gap: 57.14px;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  font-size: var(--font-20-px);
}

.footer-container-2 {
  background: #303030;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: center;
  box-sizing: border-box;
  color: var(--white-color);
}

.footer-about-company {
  flex: 28%;
}
.footer-services,
.footer-contact,
.footer-info {
  flex: 1;
}

@media all and (max-width: 1275px) {
  .footer-container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .footer-about-company {
    padding-left: 0px;
  }
  .footer-about-company {
    flex: 28%;
  }
  .footer-services,
  .footer-contact,
  .footer-info {
    flex: 1;
  }
}

@media all and (max-width: 1024px) {
  .footer-about-company {
    flex: 1;
  }
}

@media all and (max-width: 1000px) {
  .footer-mail-details {
    flex-wrap: wrap;
    gap: 60px;
  }

  .footer-company-name {
    text-align: center;
  }
  .footer-company-description {
    text-align: center;
  }
  .footer-services,
  .footer-contact,
  .footer-info {
    flex: 1;
  }
  .footer-mail-details {
    align-items: unset;
    justify-content: unset;
  }
}

@media all and (max-width: 750px) {
  .footer-mail-details {
    align-items: unset;
    justify-content: unset;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-details-menu {
    align-items: center;
    justify-content: center;
  }
  .footer-services,
  .footer-contact,
  .footer-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-address-mobile {
    display: block;
  }
  .footer-address {
    display: none;
  }
  .footer-about-company {
    padding: 0px;
  }
  .footer-mail-details {
    gap: 31px;
  }
  .footer-company-description {
    text-align: justify;
  }
  .footer-social-media {
    gap: 31.14px;
  }
  .footer-company-description {
    font-size: var(--mobile-font-15-px);
  }
}

/* @media all and (max-width: 550px) {
  .footer-info-mobile {
    display: flex;
  }
  .footer-company-description,
  .footer-info,
  .footer-services,
  .footer-social-media,
  .footer-container-2,
  .footer-copyright,
  .footer-details-heading {
    display: none;
  }
  .footer-mail-details {
    gap: 20px;
  }
  .footer-mail-details {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .footer-address-mobile {
    display: block;
  }
  .footer-address {
    display: none;
  }
  .footer-details-menu {
    margin-top: 0px;
    gap: 17px;
    justify-content: center;
    align-items: center;
  }
} */
