.event-gallery-card-heading {
  color: var(--General-Dark, #222);
  font-family: Poppins;
  font-size: var(--font-32-px);
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.event-gallery-card-description {
  color: var(--General-Dark, #222);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.event-gallery-card-button {
  margin-top: 20px;
  display: flex;
  padding: 10px 10px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 8px;
  background: #f16a23;
  box-shadow: 0px 10px 30px 0px rgba(56, 29, 219, 0.2);
  width: 140px;
  text-decoration: none;
  color: white;
}

@media all and (max-width: 1033px) {
  .event-gallery-card-description {
    margin: 0 10vw 0 10vw;
    text-align: justify;
  }
}

@media all and (max-width: 717px) {
  .event-gallery-card-description {
    margin: 0 4vw 0 4vw;
    text-align: justify;
  }
}
@media all and (max-width: 550px) {
  .event-gallery-card-heading {
    font-size: var(--mobile-font-25-px);
  }
  .event-gallery-card-description {
    margin: 0 0vw 0 0vw;
    text-align: justify;
    font-size: var(--mobile-font-16-px);
  }
}


@media screen and (min-width: 1920px) {
  .event-gallery-card-description {
    line-height: 30px;
}
}
