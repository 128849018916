.services-header {
  width: 100%;
  height: 289px;
  background: #303030;
}

#services-home-header {
  margin-top: 30px;
}

@media all and (min-width: 1750px) {
  .services-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 335px;
  }
}

@media all and (max-width: 810px) {
  .services-header {
    height: 340px;
  }
}

.services-sub-heading {
  font-size: var(--font-30-px);
  color: #f16a23;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.services-main-heading {
  font-size: var(--font-48-px);
  color: var(--white-color);
  font-weight: 800;
  line-height: 58.59px;
}

.services-main-heading-contd {
  font-size: var(--font-48-px);
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 800;
  line-height: 58.59px;
}

.available-services-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.services-header-container {
  display: flex;
  justify-content: space-between;
}

.services-header-button {
  border-radius: 30px;
  background: #f16a23;
  display: flex;
  padding: 8px 35px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  color: var(--white-color);
  cursor: pointer;
  margin-top: 50px;
  position: relative;
  z-index: 2;
  font-weight: 500;
  font-size: 14px;
}

.services-main-layout-container {
  min-height: 100vh;
}

.services-card {
  z-index: 2;
}

.services-card-top {
  margin-top: -108px;
}

@media all and (max-width: 1240px) {
  .available-services-container {
    flex-wrap: wrap;
    gap: 10px;
    box-sizing: border-box;
  }
  .services-card {
    flex: 1 0 46%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media all and (max-width: 1100px) {
  #services-home-header {
    padding-top: 30px;
  }
  .services-landing-curved-line {
    display: none;
  }
}
@media all and (max-width: 804px) {
  .services-header-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .services-main-small-heading {
    text-align: center;
  }
  .services-main-medium-heading {
    text-align: center;
  }
  .services-header-button {
    margin-top: 10px;
  }
  .services-card-top {
    margin-top: -100px;
  }
  .service-main-heading-break {
    display: none;
  }
}

@media all and (max-width: 550px) {
  #services-home-header {
    padding-top: 37px;
  }
  #home-all-services {
    padding-right: 0px;
    padding-left: 0px;
  }
  .services-card:first-child {
    margin-left: 20px;
  }
  .services-card:last-child {
    margin-right: 20px;
  }
  .services-card-top {
    margin-top: -170px;
  }
  .services-header-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    margin-top: -20px;
    /* box-sizing: border-box; */
    margin-top: -14px;
  }
  .service-main-heading-break {
    display: block;
  }
  .services-sub-heading {
    font-size: var(--mobile-font-30-px);
  }
  .services-main-heading {
    font-size: var(--mobile-font-20-px);
    line-height: normal;
  }
  .services-main-heading-contd {
    line-height: 18px;
  }
  .available-services-container {
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: auto;
    scrollbar-width: none;
    padding: 20px 0px;
  }
  .services-main-small-heading {
    text-align: left;
  }
  .services-main-medium-heading {
    text-align: left;
    margin-top: 17px;
  }
  .services-header-button {
    /* display: none; */
  }
}

@media screen and (min-width: 1920px) {
  .services-main-heading {
    line-height: 70.59px;
  }
}
