.services-card-header {
  display: flex;
  justify-content: space-between;
}

.services-card-container {
  width: 18vw;
  background-color: var(--white-color);
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 27px;
}

@media all and (min-width: 1700px) {
  .services-card-container {
    width: 300px;
  }
}

@media screen and (min-width: 2300px) {
  .services-card-container {
    width: 296px;
  }
}

.services-card-body-heading {
  color: #9d53c3;
  /* color: black; */
  font-size: var(--font-25-px);
  font-style: normal;
  font-weight: 600;
  line-height: 37.5px;
}

.services-card-body-sub-heading {
  color: #7b7b7b;
  font-family: Poppins;
  font-size: var(--font-12-8-px);
  font-style: normal;
  font-weight: 300;
  line-height: 19.2px;
}

.services-card-body-description {
  margin-top: 20px;
  color: #7b7b7b;
  font-family: Poppins;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
}

.services-card-main-svg {
  width: 110px;
  height: 110px;
  object-fit: cover;
}

.services-card-sub-svg {
  width: 110px;
  height: 110px;
}

@media all and (max-width: 1240px) {
  .services-card-container {
    width: 100%;
    box-sizing: border-box;
    background-color: var(--white-color);
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 23px;
  }
}

@media all and (max-width: 1090px) {
  .services-card-sub-svg {
    width: 90px;
    height: 90px;
  }
  .services-card-body-description {
    margin-top: 10px;
  }
}

@media all and (max-width: 550px) {
  .services-card-container {
    width: 83vw;
  }
  .services-card-body-heading {
    font-size: var(--mobile-font-20-px);
  }
  .services-card-body-sub-heading {
    font-size: var(--mobile-font-12-8-px);
  }
  .services-card-body-description {
    margin-top: 0px;
    font-size: var(--mobile-font-14-px);
    line-height: 24px;
  }
  .services-card-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
