.logut-container {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
  position: absolute;
  top: 70px;
  right: 10px;
  z-index: 99999;
  width: 200px;
  background-color: white;
  padding: 20px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 67.114px 109.061px 0px rgba(0, 0, 0, 0.05);
}

.logut-container-active {
  visibility: visible;
  opacity: 1;
}

.logout-main-container {
  position: relative;
}

.logout-buton-container {
  cursor: pointer;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 15px;
  padding: 10px 20px 10px 20px;
  width: 100%;
}

.logout-buton-container:hover {
  background-color: rgb(233, 150, 150);
}

.logout-close {
  position: relative;
  width: 100vw;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  background-color: rgb(233, 150, 150);
}
