.add-services-body {
  display: flex;
  gap: 40px;
}

.add-service-body-col-1 {
  flex: 1;
  flex-grow: inherit;
}

.add-service-body-col-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex: 5;
  align-items: flex-end;
}

.add-service-dropzone-size {
  width: 150px;
  height: 150px;
}

.add-services-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.add-services-clear-button {
  color: #000;
  font-family: Poppins;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  width: 100px;
  height: 45px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 2px solid #000;
  background-color: transparent;
  cursor: pointer;
}

.add-services-submit-button {
  cursor: pointer;
  border: none;
  cursor: pointer;
  border-radius: 40px;
  background: #f16a23;
  display: inline-flex;
  width: 100px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media all and (max-width: 1200px) {
  .add-services-footer {
    margin-bottom: 50px;
  }
}

@media all and (max-width: 926px) {
  .add-services-body {
    flex-direction: column;
  }
}

@media all and (max-width: 926px) {
  .add-service-dropzone-size {
    width: 100%;
  }
  .add-services-body {
    display: flex;
    gap: 20px;
  }
}

@media all and (max-width: 638px) {
  .add-service-body-col-2 {
    align-items: unset;
    flex-direction: column;
  }
}

@media all and (max-width: 550px) {
  .add-services-footer {
    justify-content: center;
  }

  .add-services-clear-button,
  .add-services-submit-button {
    width: 120px;
  }
  .add-services-clear-button {
    font-size: var(--mobile-font-14-px);
  }
}
