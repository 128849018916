.polygon-svg-container {
  position: absolute;
  z-index: -100;
}

.polygon-svg-style {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media all and (max-width: 550px) {
  .polygon-svg-container {
    display: none;
  }
}
