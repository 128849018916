.dashboard-header-component-container {
  display: flex;
  background-color: #000000;
  flex-direction: column;
  /* width: 100%; */
  border-radius: 20px;
  padding: 20px;
  gap: 20px;
  color: white;
  position: relative;
  overflow: hidden;
  /* overflow-clip-margin: 20px; */
}

.dashboard-header-logo {
  width: 200px;
}

.dashboard-header-user-name {
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-32-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard-header-user-role {
  color: #eeeef2;
  font-family: Poppins;
  font-size: var(--font-18-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard-header-address {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  /* justify-content: center; */
}

.dashboard-header-info {
  display: flex;
  align-items: flex-end;
  gap: 60px;
}

.dashboard-header-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fb7d5b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-25-px);
}

.dashboard-header-phone,
.dashboard-header-email {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-header-circle-orange {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #fb7d5b;
  border-radius: 50%;
  right: -25px;
  bottom: -130px;
  z-index: 9;
}

.dashboard-header-circle-yellow {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #fcc43e;
  border-radius: 50%;
  right: -80px;
  overflow: hidden;
  z-index: 10;
}
