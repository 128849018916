.landing-home-container {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.landing-home-main-heading {
  font-size: var(--font-90-px);
  color: #000;
  font-weight: 800;
  line-height: 78px;
}

.landing-home-description-text {
  color: var(--description-text-color);
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

.landing-home-left-container {
  display: flex;
  flex-direction: column;
  gap: 23px;
  flex: 1;
}

.landing-button {
  width: fit-content;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  background: #f5b02f;
  padding: 23px 60px;
  color: white;
  font-family: Roboto;
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.landing-home-carousel-heading {
  color: #333;
  font-family: Poppins;
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.landing-carousel {
  margin-top: 30px;
}

.landing-home-image-container {
  width: 783px;
  height: 747px;
  object-fit: contain;
}

.polygon2-landing {
  position: absolute;
  right: 400px;
  top: 250px;
  background-color: #000;
  box-sizing: border-box;
}

.home-landing-mobile-content {
  display: none;
}

@media all and (max-width: 1267px) {
  .landing-home-container {
    flex-direction: column-reverse;
  }
  .landing-home-break {
    display: none;
  }
  /* .landing-home-description-text{
    text-align: center;
  } */
  .landing-home-left-container {
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .landing-home-carousel-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  .landing-home-main-heading {
    text-align: center;
  }
}

@media all and (max-width: 768px) {
  .landing-home-image-container {
    width: 570px;
    height: 570px;
  }
  .polygon2-landing {
    display: none;
  }
  .home-carousel-container :nth-child(2) {
    display: none;
  }
}

@media all and (max-width: 644px) {
  #home-page-landing {
    padding-top: 4px;
  }

  #home-benefits {
    padding-top: 10px;
  }

  #home-customer-base {
    padding-top: 0px;
  }
  .landing-button {
    padding: 14px 26px;
    font-size: var(--mobile-font-16-px);
  }
  .landing-home-image-container {
    width: 500px;
    height: 500px;
  }
  .polygon2-landing {
    display: none;
  }
  .home-carousel-container :nth-child(2) {
    display: none;
  }
  .landing-home-image-container {
    width: 95vw;
    height: auto;
  }
  .landing-home-break {
    display: block;
  }
  .landing-home-left-container {
    align-items: flex-start;
  }
  .landing-home-main-heading {
    text-align: left;
    color: #000;
    font-family: Poppins;
    font-size: var(--mobile-font-30-px);
    font-style: normal;
    font-weight: 700;
    line-height: 41px; /* 160.321% */
  }
  .landing-home-description-text {
    line-height: 27px;
}
  .home-landing-web-content {
    display: none;
  }

  .home-landing-mobile-content {
    font-size: var(--mobile-font-16-px);
    display: block;
  }
  .landing-home-carousel-heading {
    font-size: var(--mobile-font-15-px);
  }
  .landing-home-left-container {
    margin-bottom: 0px;
    width: 100%;
    margin-left: 15px;
  }
}

@media screen and (min-width: 1920px) {
  .landing-home-main-heading {
    line-height: 90px;
  }
}
