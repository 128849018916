@import url(../DashboardTheme.css);

.dashboard-header-container {
  display: flex;
  padding: var(--dashboard-header-container-padding);
  justify-content: space-between;
  align-items: center;
}

.dashboard-header-heading {
  color: var(--dashboard-main-text-color);
  font-family: Poppins;
  font-size: var(--font-36-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard-header-loggedin-user-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
}

.dashboard-header-loggedin-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard-header-loggedin-user-name {
  color: #303972;

  /* Text/Text Semi Bold 2 */
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard-header-loggedin-user-role {
  color: #a098ae;

  /* Text/Text Regular 2 */
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-header-loggedin-user-data {
  text-align: right;
}

@media all and (max-width: 1210px) {
  .dashboard-header-container {
    padding-left: 60px;
  }
  .dashboard-header-loggedin-user-image {
    width: 40px;
    height: 40px;
  }

  .dashboard-header-heading {
    font-size: var(--mobile-font-17-px);
  }
}
