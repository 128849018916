.events-list-dark {
  font-size: var(--font-48-px);
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.events-list-color {
  font-size: var(--font-48-px);
  color: #ff5300;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.events-list-heading {
  text-align: center;
  margin-top: 80px;
}

.events-image-container {
  margin-top: 30px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.ev-des-img {
  width: 100%;
  object-fit: cover;
  height: 300px;
  border-radius: 20px;
}

.ev-des-grid-1 {
  display: grid;
  gap: 20px;
  grid-template-columns: 3fr 1fr;
  width: 100%;
}

.ev-des-grid-2 {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 3fr;
  width: 100%;
}

.ev-des-grid-3 {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

@media all and (max-width: 1212px) {
  .ev-des-grid-1,
  .ev-des-grid-2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (max-width: 622px) {
  .ev-des-grid-3 {
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

@media all and (max-width: 550px) {
  .ev-des-grid-1,
  .ev-des-grid-2 {
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
  }
}
