.curved-lines-container {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
}

@media all and (max-width: 800px) {
  .curved-lines-container {
    display: none;
  }
}
