.spinner-new {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: var(--primary-color);
  animation: spin 1s linear infinite;
  transform: translate(-50%, -50%);
}

.spinner-container-new {
  margin-top: 75px;
  margin-bottom: 75px;
  /* backdrop-filter: blur(10px); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  width: 100%;
}

.button-spinner-new{
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  border-top-color: var(--primary-color);
  animation: spin 1s linear infinite;
  transform: translate(-50%, -50%);
}

.button-spinner-container-new {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  width: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-inside-children {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
}

.spinner-inside-children-active {
  opacity: 1;
  visibility: visible;
}