.add-blog-add-component {
  font-size: 40px;
  color: gray;
  cursor: pointer;
}
.add-blog-add-component:hover {
  color: rgb(0, 0, 0);
}

.add-blog-main-details-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.add-blog-other-components {
  font-size: 20px;
  color: gray;
  display: flex;
  gap: 20px;
}

.add-blog-other-components-hover {
  cursor: pointer;
}

.add-blog-other-components-hover:hover {
  color: rgb(0, 0, 0);
}

.add-blog-other-component-control {
  margin-top: 0px;
  margin-left: 0px;
  display: flex;
  gap: 16px;
  align-items: center;
}

/* Up, Down (Move), Delete */
.add-blog-single-component-container {
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid rgb(255, 255, 255);
  justify-content: space-between;
}
.add-blog-single-component-container:hover {
  border-bottom: 1px solid #c1bbeb;
}
.add-blog-single-component-element {
  flex: 1;
}

.add-blog-single-component-controller {
  display: flex;
  gap: 10px;
}
.add-blog-single-component-container:hover
  .add-blog-single-component-controller {
  display: flex;
}
.add-blog-single-component-up {
  color: gray;
  cursor: pointer;
}
.add-blog-single-component-down {
  color: gray;
  cursor: pointer;
}
.add-blog-single-component-delete {
  color: rgb(226, 95, 95);
  cursor: pointer;
}

/* .add-blog-date-time-container {
  border: none;
  border-left: 1px solid black;
  padding: 0.5rem;
  padding-left: 20px;
} */

.add-blog-submit-button-container {
  display: flex;
  justify-content: flex-end;
}

@media all and (max-width: 550px) {
  .add-blog-single-component-controller {
    gap: 12px;
    flex-direction: column;
  }
}
