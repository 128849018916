.all-events-sl-landing-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.all-events-sl-landing-heading-dark {
  font-size: var(--font-90-px);
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 70px;
}

.all-events-sl-landing-heading-color {
  font-size: var(--font-90-px);
  color: #f5af30;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 70px;
}

.all-events-sl-landing-heading {
  flex: 1;
}

.all-events-sl-landing-image-container {
  position: relative;
  /* background-color: green; */
  height: 548px;
  width: 632px;
  overflow: hidden;
}

.all-events-sl-landing-image {
  border-radius: 20px;
}

.all-events-landing-img-1 {
  border: 6px solid #fdbf21a1;
  position: absolute;
  top: 0px;
  width: 188px;
  height: 110px;
  object-fit: cover;
  z-index: 10;
}

.all-events-landing-img-2 {
  position: absolute;
  top: 56px;
  left: 88px;
  width: 544px;
  height: 303px;
  object-fit: cover;
  z-index: 9;
}

.all-events-landing-img-3 {
  border: 6px solid #fdbf21a1;
  position: absolute;
  top: 227px;
  width: 238px;
  height: 203px;
  object-fit: cover;
  z-index: 10;
  /* display: none; */
}

.all-events-landing-img-4 {
  position: absolute;
  bottom: 0;
  left: 88px;
  width: 234px;
  height: 175px;
  object-fit: cover;
  z-index: 9;
  /* display: none; */
}
.all-events-landing-img-5 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  /* left: 50px; */
  width: 287px;
  height: 175px;
  object-fit: cover;
  z-index: 10;
  /* display: none; */
}

.all-event-cards {
  padding: 50px 200px 50px 200px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.all-events-gallery-heading-dark {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-48-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.all-events-gallery-heading-color {
  color: #ff5300;
  font-family: Poppins;
  font-size: var(--font-48-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.all-events-gallery-heading {
  margin-top: 150px;
  text-align: center;
}

@media all and (max-width: 1300px) {
  .all-event-cards {
    padding: 50px 20px 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
}

@media all and (max-width: 1200px) {
  .all-events-sl-landing-image-container {
    transform: scale(0.8);
    margin-right: -53px;
  }
}

@media all and (max-width: 1050px) {
  .all-events-sl-landing-image-container {
    transform: scale(0.7);
    margin-right: -88px;
    margin-left: -80px;
  }
}

@media all and (max-width: 910px) {
  .all-events-sl-landing-image-container {
    transform: scale(0.6);
    margin-right: -117px;
    margin-left: -114px;
  }
  .events-all-gallery-styles {
    display: none;
  }
}

@media all and (max-width: 780px) {
  .eventsgallerycard2-image-container {
    margin-top: -43px;
    margin-bottom: -43px;
  }
  .eventsgallerycard2-image-container {
    transform: scale(0.8);
  }
  .all-events-sl-landing-container {
    flex-direction: column-reverse;
  }
  .all-events-sl-landing-image-container {
    transform: scale(0.6);
    margin-right: -117px;
    margin-bottom: -91px;
    margin-left: -114px;
    margin-top: -96px;
  }
}

@media all and (max-width: 600px) {
  .eventsgallerycard2-image-container {
    transform: scale(0.7);
  }
  .eventsgallerycard1-container {
    flex-direction: column;
    gap: 30px;
    transform: scale(0.95);
  }
  .eventsgallerycard2-image-container {
    margin-top: -74px;
    margin-bottom: -86px;
  }
}

@media all and (max-width: 550px) {
  .all-events-gallery-heading {
    margin-top: 33px;
    text-align: center;
  }
  .all-events-gallery-heading-dark,
  .all-events-gallery-heading-color {
    font-size: var(--mobile-font-25-px);
  }

  .all-event-cards {
    padding: 50px 20px 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .all-event-cards {
    padding: 0px 20px 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .all-events-sl-landing-heading-dark,
  .all-events-sl-landing-heading-color {
    font-size: var(--mobile-font-30-px);
    font-weight: 700;
    line-height: 40px;
  }
  .all-events-sl-landing-heading {
    align-self: flex-start;
    margin-left: 20px;
  }
  .all-events-sl-landing-container {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 0px;
  }
  #events-all-gallery{
    padding-top: 0px;
  }
}

@media all and (max-width: 480px) {
  .eventsgallerycard2-image-container {
    margin-top: -114px;
    margin-bottom: -113px;
  }
  .eventsgallerycard1-image-container {
    transform: scale(0.8);
  }
  .eventsgallerycard2-image-container {
    transform: scale(0.5);
  }
}

@media all and (max-width: 455px) {
  .all-events-sl-landing-image-container {
    transform: scale(0.5);
    margin-right: -117px;
    margin-bottom: -123px;
    margin-left: -114px;
    margin-top: -130px;
  }
}

@media all and (max-width: 400px) {
  .all-events-sl-landing-image-container {
    transform: scale(0.5);
    margin-right: -117px;
    margin-bottom: -152px;
    margin-left: -114px;
    margin-top: -157px;
  }
  .all-events-sl-landing-container {
    display: flex;
    gap: 41px;
    align-items: center;
  }
  .all-events-sl-landing-heading {
    align-self: flex-start;
    margin-left: 9px;
  }
}

@media screen and (min-width: 1920px) {
  .all-events-sl-landing-heading-color,
  .all-events-sl-landing-heading-dark {
    line-height: 90px;
  }
}
