.portfolio-description-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 70px;
}

.portfolio-description-title {
  color: #f5af30;
  font-family: Poppins;
  font-size: var(--font-90-px);
  font-style: normal;
  font-weight: 800;
  line-height: 48px; /* 53.333% */
  letter-spacing: 1px;
}

.portfolio-description-main-container {
  padding: 20px 70px 20px 70px;
}

.portfolio-description-body {
  padding: 20px 70px 0px 70px;
  border-radius: 20px;
  display: flex;
  margin-top: 50px;
  gap: 16px;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.31);
}

@media all and (max-width: 1200px) {
  .portfolio-description-body {
    padding: 0px 40px 0px 40px;
  }
}

@media all and (max-width: 1100px) {
  .portfolio-description-body {
    padding: 0px 10px 0px 10px;
  }
}

.portfolio-description-body-image {
  width: 100%;
  height: 279px;
  object-fit: cover;
  border-radius: 40px;
}

.portfolio-description-body-heading {
  color: var(--Black-100, var(--Black-100, #07070c));
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-48-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.portfolio-description-body-date {
  color: var(--Black-100, var(--Black-100, #07070c));
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-25-px);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 5px;
}

.portfolio-description-body-description {
  border-radius: 45px;
  border: 1.5px solid var(--Black-10, #f4f4f6);
  padding: 20px 30px 20px 30px;
  color: var(--Black-50, var(--Black-50, #6e727e));
  font-family: Poppins;
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: justify;
}

.portfolio-description-body-heading-container {
  border-radius: 45px;
  border: 1.5px solid var(--Black-10, #f4f4f6);
}

/* circles */
.portfolio-description-circle-1 {
  position: absolute;
  width: 250px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #ffdf74;
  z-index: -100;
  top: 850px;
  left: 10px;
}

.portfolio-description-circle-2 {
  position: absolute;
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #f5f0c2;
  z-index: -100;
  top: 800px;
  right: -80px;
  clip-path: inset(0px 80px 0 0px);
}

.portfolio-description-circle-3 {
  position: absolute;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #f5f0c2;
  z-index: -100;
  top: 1200px;
  left: 500px;
}

.portfolio-description-circle-4 {
  position: absolute;
  width: 500px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #f5f0c2;
  z-index: -100;
  top: 1100px;
  left: 800px;
}
@media all and (max-width: 1460px) {
  .portfolio-description-circle-4 {
    width: 300px;
    height: 300px;
  }
}

@media all and (max-width: 1200px) {
  .portfolio-description-circle-4 {
    width: 200px;
    height: 200px;
  }
}

@media all and (max-width: 1050px) {
  .portfolio-description-circle-4 {
    display: none;
  }
}

.portfolio-description-body-tags {
  display: flex;
  justify-content: space-around;
}

.portfolio-description-body-tags-container {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.portfolio-description-used-topic {
  border-radius: 999px;
  background: #ffe99f;
  color: #212529;
  text-align: center;
  font-family: "Work Sans";
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding: 10px 20px 10px 20px;
}

.portfolio-description-more-blogs {
  margin-top: 60px;
}
.portfolio-description-more-blogs-main-heading {
  font-size: var(--font-48-px);
  text-align: center;
  color: var(--Black, #373737);
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 58.59px;
  margin-bottom: 50px;
}

.portfolio-description-more-blogs-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

/* .portfolio-description-body-icon-container {
  display: flex;
  gap: 8px;
  border-radius: 40px;
  border: 3px solid var(--Background-Black-Background, #090909);
  background: var(--white-100, #fff);
  width: 179px;
  padding: 16px;
  align-items: center;
  justify-content: space-around;
} */

.portfolio-description-body-icon-container {
  display: flex;
  /* flex-direction: column; */
  gap: 8px;
  border-radius: 40px;
  /* border: 3px solid var(--Background-Black-Background, #090909); */
  background: #ababab24;
  width: 179px;
  padding: 16px;
  align-items: center;
  justify-content: space-around;
}

.body-desc-icon {
  width: 40px;
  height: 40px;
  /* object-fit: cover; */
}
.portfolio-desc-icon-p1 {
  font-size: var(--mobile-font-12-px);
  font-weight: 600;
}
.portfolio-desc-icon-p2 {
  font-size: var(--mobile-font-20-px);
  font-weight: 500;
}
.portfolio-description-body-icons {
  display: flex;
  justify-content: space-around;
  padding-left: 10vw;
  padding-right: 10vw;
}

@media all and (max-width: 1368px) {
  .portfolio-description-body-icons {
    display: flex;
    justify-content: space-around;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media all and (max-width: 1200px) {
  .portfolio-description-more-blogs-item {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
}

@media all and (max-width: 1130px) {
  .portfolio-description-body-icons {
    display: flex;
    justify-content: space-around;
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

@media all and (max-width: 974px) {
  .portfolio-description-body-icon-desc {
    display: none;
  }
  .portfolio-description-body-icon-container {
    display: flex;
    /* flex-direction: column; */
    gap: 8px;
    border-radius: 17px;
    border: 3px solid var(--Background-Black-Background, #090909);
    background: var(--white-100, #fff);
    width: 68px;
    padding: 16px;
    align-items: center;
    justify-content: space-around;
  }
}

@media all and (max-width: 968px) {
  .blog-desc-polygon-1 {
    display: none;
  }
}

@media all and (max-width: 880px) {
  .portfolio-description-main-container {
    padding: 0px;
  }
}

@media all and (max-width: 710px) {
  .portfolio-description-circle-1,
  .portfolio-description-circle-3,
  .portfolio-description-circle-4,
  .portfolio-description-search {
    display: none;
  }

  .portfolio-description-body-tags-container {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: auto;
    scrollbar-width: none;
  }
}

@media all and (max-width: 550px) {
  .portfolio-description-body-icons {
    display: flex;
    justify-content: center;
    padding-left: 2vw;
    padding-right: 2vw;
    gap: 20px;
    margin-top: 20px;
  }
  .portfolio-description-body-icon-container {
    display: flex;
    /* flex-direction: column; */
    gap: 8px;
    border-radius: 17px;
    border: 2px solid var(--Background-Black-Background, #090909);
    background: var(--white-100, #fff);
    width: 30px;
    padding: 9px;
    align-items: center;
    justify-content: space-around;
  }
  .body-desc-icon {
    width: 20px;
    height: 20px;
    /* object-fit: cover; */
  }
  .portfolio-description-body-heading-container {
    padding: 20px;
    border-radius: 60px;
  }

  .portfolio-description-body-image {
    height: 379px;
  }
  .portfolio-description-header {
    display: none;
  }
  .portfolio-description-body {
    margin-top: 0px;
    gap: 2px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .portfolio-description-body-heading {
    font-size: var(--mobile-font-28-px);
    font-weight: 500;
  }
  .portfolio-description-body-date {
    font-size: var(--mobile-font-17-px);
  }
  .portfolio-description-body-description {
    border-radius: 60px;
    font-size: var(--mobile-font-14-px);
    font-weight: 400;
    padding: 33px 39px 33px 39px;
  }
  .portfolio-description-used-topic {
    font-size: var(--mobile-font-16-px);
  }
  .portfolio-description-more-blogs-main-heading {
    font-size: var(--mobile-font-28-px);
  }
}
