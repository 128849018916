.simple-layout-main-container section {
  padding: 20px 50px 20px 50px;
  box-sizing: border-box;
  overflow: hidden;
}
.simple-layout-main-container {
  min-height: calc(100vh - 368px - 100px);
}


@media all and (max-width: 1142px){
  .simple-layout-main-container {
    min-height: calc(100vh - 368px - 65px);
  }
}

@media all and (min-width: 1700px) {
  .simple-layout-main-container section {
    padding: 50px 150px 20px 150px;
    overflow: hidden;
  }
}
@media all and (max-width: 550px) {
  .simple-layout-main-container section {
    padding: 45px 25px 20px 25px;
    overflow: hidden;
  }
}

@media all and (min-width: 1750px) {
  .simple-layout-main-container section {
    padding: 10px 90px 20px 90px;
    overflow: hidden;
    max-width: 1800px;
    min-width: 1680px;
  }

  .simple-layout-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 368px - 100px);
  }
}
