.about-landing-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-landing-container-bottom {
  gap: 70px;
}

.about-landing-primary {
  color: var(--primary-color);
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 90px; /* 94.5px */
  letter-spacing: -0.9px;
  font-size: var(--font-90-px);
}

.about-landing-secondary {
  color: #f16a23;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: 106.3%;
  font-size: var(--font-48-px);
  letter-spacing: -0.48px;
}

.about-landing-black {
  font-size: var(--font-90-px);
  color: #331b3b;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 90px;
  letter-spacing: -0.9px;
}

.about-landing-black-2 {
  font-size: var(--font-48-px);
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 105%;
  letter-spacing: -0.9px;
}

.about-landing-description {
  color: #000;
  font-family: Poppins;
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 46.5px */
  letter-spacing: 0.6px;
}

.about-landing-image {
  width: 612px;
}

.about-who-we-are-image {
  width: 620px;
}
.who-we-are-heading-set {
  display: flex;
  gap: 20px;
  align-items: end;
}

.about-heading-set {
  display: flex;
  gap: 10px;
  align-items: end;
}

.about-landing-primary-vertical-line {
  width: 75px;
  height: 8px;
  background: var(--primary-color);
  margin-bottom: 8px;
}

.about-who-we-are-description {
  color: rgba(0, 0, 0, 0.66);
  font-family: Poppins;
  font-size: var(--font-45-px);
  font-style: normal;
  font-weight: 600;
  line-height: 146.3%; /* 65.835px */
  letter-spacing: 0.9px;
  padding-right: 5vw;
  margin-top: 20px;
}

.about-landing-right-polygon {
  position: absolute;
  z-index: -100;
  width: 650px;
  right: 0px;
  top: 0px;
}

.about-landing-design {
  border-radius: 24.522px;
  background: linear-gradient(
    127deg,
    rgba(255, 255, 255, 0.12) 28.69%,
    rgba(255, 255, 255, 0) 100%
  );
}

.about-landing-top-heading-tab {
  display: none;
}

.about-heading-underline {
  margin-top: 20px;
  width: 15vw;
  height: 8px;
  background: var(--primary-color);
  margin-bottom: 15px;
}

@media all and (max-width: 1350px) {
  #about-page-who-we-are{
    margin-top: 50px;
  }
  .about-who-we-are-image {
    width: 420px;
  }
  .about-landing-container-top {
    /* flex-direction: column-reverse; */
  }
  .about-landing-image {
    width: 429px;
  }
  /* .about-landing-top-heading-big {
    display: none;
  } */
  .about-landing-break {
    display: none;
  }
  .about-landing-container-bottom {
    /* flex-direction: column; */
  }
  .who-we-are-heading-set {
    align-items: center;
    justify-content: center;
  }
  .about-who-we-are-description {
    font-size: var(--font-30-px);
    text-align: justify;
    margin-bottom: 50px;
    padding-right: 0px;
  }
  .about-polygon2 {
    display: none;
  }
  .about-who-we-are-description {
    margin-top: 20px;
  }
  .about-landing-primary-vertical-line {
    margin-bottom: -16px;
  }
  .about-landing-black,
  .about-landing-primary {
    font-size: 53px;
    line-height: 65px;
  }
  #abot-page-landing {
    margin-top: 50px;
  }
}

@media all and (max-width: 1020px) {
  .about-landing-container-top {
    flex-direction: column;
    gap: 37px;
  }
  .about-landing-top-heading-tab {
    display: block;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 30px;
  }
  .about-landing-top-heading-big {
    display: none;
  }
  .about-landing-break {
    display: none;
  }
  .about-landing-container-bottom {
    flex-direction: column;
  }
}

@media all and (max-width: 550px) {
  #about-page-who-we-are{
    margin-top: 0px;
  }
  #about-page-who-we-are {
    padding-top: 45px;
  }
  #about-page-who-we-have {
    padding-top: 70px;
  }
  .about-landing-container-top {
    flex-direction: column-reverse;
    gap: 0px;
  }
  .about-landing-description {
    margin-top: 30px;
    text-align: justify;
  }
  #abot-page-landing {
    margin-top: 0px;
  }
  .about-who-we-are-description {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .about-who-we-are-image {
    width: 90vw;
  }
  .about-landing-top-heading-big {
    display: block;
  }
  .about-landing-top-heading-tab,
  .about-heading-underline {
    display: none;
  }
  .about-landing-image {
    width: 85vw;
  }
  /* .about-landing-break {
    display: block;
  } */
  .about-landing-top-heading-big {
    text-align: center;
    margin-top: 20px;
  }
  .about-landing-right-polygon {
    position: absolute;
    z-index: -100;
    width: 480px;
    height: 750px;
    right: 0px;
    top: -240px;
  }
  .about-polygon1 {
    display: none;
  }

  .about-landing-container-bottom {
    gap: 20px;
  }
  .about-who-we-are-description {
    margin-top: 20px;
    text-align: center;
    font-size: var(--mobile-font-20-px);
    padding-right: 0px;
  }

  .about-landing-primary-vertical-line {
    margin-bottom: -18px;
  }

  .who-we-are-heading-set {
    gap: 5px;
  }

  .about-landing-primary,
  .about-landing-black,
  .about-landing-secondary,
  .about-landing-black-2 {
    line-height: 49px;
    font-size: var(--mobile-font-30-px);
  }

  .about-landing-primary,
  .about-landing-black {
    line-height: 49px;
  }

  .about-landing-black {
    color: black;
  }
}

@media all and (min-width: 1900px) {
  .about-landing-primary,
  .about-landing-black {
    font-size: var(--font-65-px);
  }
}
