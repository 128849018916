.db-mgmt-header-search-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.db-mgmt-header-button {
  display: flex;
  padding: 15px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #000;
  text-decoration: none;
  color: #fff;
  box-shadow: 0px 20px 50px 0px rgba(191, 21, 108, 0.05);
}

.db-mgmt-header-table-container {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  min-height: 55vh;
  height: calc(100vh - 107px - 175px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-management-header-clear-body {
  /* width: 100%; */
  background-color: white;
  border-radius: 20px;
  padding: 10px 30px 10px 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
}

.dashboard-management-header-clear-button {
  background-color: rgba(226, 226, 226, 0.692);
  border: none;
  border-radius: 20px;
  padding: 3px 10px 3px 10px;
  margin-left: 10px;
}
.dashboard-management-header-clear-body span {
  font-weight: 400;
}
.dashboard-search-filter-icon {
  color: gray;
}
.dashboard-management-header-clear-button:hover {
  background-color: rgba(235, 144, 144, 0.692);
}
@media all and (max-width: 1210px) {
  .db-mgmt-header-button {
    padding: 10px 23px;
  }
}
@media all and (max-width: 550px) {
  .db-mgmt-header-search-add {
    margin-bottom: 0px;
  }
  .db-mgmt-header-table-container {
    padding: 12px;
    height: unset;
    overflow: unset;
    background-color: unset;
  }
}

@media all and (max-width: 420px) {
  .db-mgmt-header-button-name {
    display: none;
  }
}
