#services-landing {
  margin-top: 60px;
}

.services-page-landing-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(
    90deg,
    #ffffff,
    #ffffff,
    #ffffffdf,
    #ffffff11,
    transparent
  );
}

.services-landing-image {
  z-index: -1;
  height: 600px;
  flex-shrink: 0;
  border-radius: 20px;
}

.services-page-sub-heading {
  font-family: Poppins;
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 3px;
  color: #acadff;
  font-size: var(--font-30-px);
}

.services-page-main-heading-dark-color {
  color: var(--black-70, #3d3d3d);
  font-family: Poppins;
  font-size: var(--font-90-px);
  font-style: normal;
  font-weight: 800;
  line-height: 64px;
  font-size: var(--font-90-px);
}

.services-page-main-heading-primary-color {
  color: #f5af30;
  font-family: Poppins;
  font-size: var(--font-90-px);
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-90-px);
  line-height: 64px;
}

.services-our-services-grid {
  margin-top: 50px;
  padding-left: 80px;
  padding-right: 80px;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 80px;
}

.services-our-services-sub-heading {
  text-align: center;
  color: #f16a23;
  font-family: Poppins;
  font-size: var(--font-24-px);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.services-our-services-main-heading {
  text-align: center;
  font-size: var(--font-48-px);
  color: #000000;
  font-family: Poppins;
  /* font-size: 48px; */
  font-style: normal;
  font-weight: 600;
  line-height: 58.59px;
}

.service-page-heading-underline {
  width: 42px;
  height: 2px;
  background-color: var(--secondary-color);
  margin-bottom: 7px;
}

.services-page-sub-heading-set {
  display: flex;
  gap: 5px;
  align-items: flex-end;
  justify-content: center;
}

.services-page-whole-headings {
  padding-left: 30px;
  flex: 1;
}

@media all and (max-width: 1572px) {
  .services-landing-image {
    height: 600px;
    width: 1000px;
    object-fit: cover;
  }
}

@media all and (max-width: 1492px) {
  .service-our-polygons {
    display: none;
  }
  .services-landing-image {
    height: 600px;
    width: 60vw;
    object-fit: cover;
  }
}

@media all and (max-width: 1230px) {
  .services-our-services-grid {
    grid-template-columns: 1fr 1fr;
  }

  .additional-styling-services-landing {
    display: none;
  }
}

@media all and (max-width: 1160px) {
  #services-our-services {
    margin-top: 30px;
  }
  .services-page-main-heading-primary-color {
    line-height: 78px;
  }

  #services-landing {
    padding: 0px;
    height: 600px;
    margin-top: 0px;
  }
  .services-landing-image {
    height: 600px;
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
    position: absolute;
  }
  .services-page-main-heading-dark-color {
    line-height: 78px;
    color: #ffffff;
  }

  .services-page-landing-container {
    position: relative;
    width: 100%;
    border-radius: 0px;
    background: rgba(67, 67, 67, 0.678);
    height: 100%;
  }
}

/* @media all and (max-width: 1100px) {
  .services-landing-image {
    height: 300px;
    width: 400px;
    object-fit: cover;
  }
} */

@media all and (max-width: 740px) {
  .services-our-services-grid {
    grid-template-columns: 1fr;
  }
}

@media all and (max-width: 620px) {
  .services-our-services-grid {
    margin-top: 0px;
  }
  .services-our-services-grid {
    gap: 38px;
  }
  .services-our-services-grid {
    padding-left: 10px;
    padding-right: 10px;
  }
  .service-page-heading-underline {
    display: none;
  }

  .services-our-services-sub-heading {
    font-weight: 500;
  }

  .services-our-services-main-heading {
    color: black;
  }
}

@media all and (max-width: 550px) {
  #services-landing {
    padding: 0px;
    height: calc(85vh - 61.98px);
    margin-top: 0px;
  }
  .services-landing-image {
    height: calc(100vh - 61.98px);
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
    position: absolute;
  }
  #services-our-services {
    padding-left: 10px;
    padding-right: 10px;
  }
  .services-our-services-sub-heading {
    text-align: center;
    /* font-size: var(--mobile-font-20-px); */
    /* font-weight: 500; */
    /* line-height: 37px; */
    /* font-family: Epilogue; */

    color: #f16a23;
    font-family: Poppins;
    font-size: var(--mobile-font-24-px);
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 100% */
  }

  .service-page-heading-underline {
    display: block;
  }

  .service-page-heading-underline {
    width: 37px;
    height: 2px;
    background-color: var(--secondary-color);
    margin-bottom: 7px;
  }

  .services-our-services-main-heading {
    color: black;
    font-size: 24px;
  }

  .services-our-services-main-heading h2 {
    font-family: Epilogue;
  }
}

@media screen and (min-width: 1920px) {
  .services-page-main-heading-dark-color {
    line-height: 90px;
  }
  .services-our-services-main-heading {
    line-height: 70px;
  }
}
