.login-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: black;
}

.login-element-container {
  background-color: #262626;
  display: flex;
  flex-direction: column;
  padding: 60px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  gap: 25px;
  width: 441px;
}

.login-page-heading {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: white;
}

.login-logo {
  width: 135px;
  height: auto;
}

.login-image-contaioner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-button {
  border: none;
  background-color: var(--primary-color);
  color: white;
  font-weight: 400;
  font-size: 15px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media all and (max-width: 600px) {
  .login-element-container {
    background-color: #262626;
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-top: 90px;
    padding-bottom: 48px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
    gap: 25px;
    width: 376px;
    margin: 29px;
  }

  .login-page-heading {
    font-size: 17px;
  }
}
