.dashboard-client-details-container {
  border-radius: 20px;
  background: var(--white-100, #fff);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* height: calc(100vh - 182px - 133px - 225px); */
  /* overflow: auto; */
}

.dashboard-client-details-container-header {
  display: flex;
  justify-content: space-between;
}

.dashboard-client-details-heading {
  color: #303972;
  font-family: Poppins;
  font-size: var(--font-24-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard-client-details-add-icon {
  background-color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-14-px);
}

.dashboard-client-details-add-icon-style {
  stroke-width: 20px;
}

.dashboard-single-client-collection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
