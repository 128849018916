.contact-us-landing-container {
  display: flex;
  /* min-height: 80vh; */
  justify-content: space-between;
  align-items: center;
}

.contact-us-landing-image-container {
  position: relative;
  margin-right: 20px;
  top: 0px;
  /* background: #f78e02; */
  width: 645px;
  height: 600px;
}

.contactus-landing-img {
  right: 0;
  position: absolute;
  width: 225px;
  height: 285px;
  object-fit: cover;
  border: 5px solid white;
}

.contactus-landing-img1 {
  top: 212px;
  right: 405px;
  z-index: 11;
}

.contactus-landing-img2 {
  top: 32px;
  right: 284px;
  z-index: 10;
}
.contactus-landing-img3 {
  top: 288px;
  right: 124px;
  z-index: 11;
}

.contactus-landing-img4 {
  top: 119px;
  right: 10px;
  z-index: 10;
}

.contactus-landing-heading {
  font-size: var(--font-90-px);
  color: var(--Gray-1, #333);
  font-family: Poppins;
  font-size: var(--font-90-px);
  font-style: normal;
  font-weight: 800;
  line-height: 110px;
}

.contactus-landing-desc {
  color: var(--Gray-1, #333);
  font-family: Poppins;
  font-size: var(--font-30-px);
  font-style: normal;
  font-weight: 500;
  line-height: 39px; /* 153.333% */
}

@media all and (max-width: 1360px) {
  .contactus-landing-img1,
  .contactus-landing-img2,
  .contactus-landing-img4 {
    display: none;
  }
  .contactus-landing-img {
    right: 0;
    width: 100%;
    object-fit: cover;
    border: none;
    position: absolute;
    top: 0px;
    height: 500px;
    z-index: -1;
    filter: brightness(0.3);
  }
  .contact-us-landing-image-container {
    position: unset;
    margin-right: 20px;
    top: 0px;
    /* background: #f78e02; */
    width: unset;
    height: unset;
  }

  .contact-us-landing-container {
    gap: 20px;
    position: relative;
    height: 500px;
    margin-left: -50px;
    margin-right: -50px;
  }
  .contact-us-landing-headings {
    width: 100vw;
    padding-left: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
  }

  #contact-us-landing-section {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactus-landing-heading,
  .contactus-landing-desc {
    color: white;
  }
  .contact-us-landing-heading-part-1 {
    color: var(--secondary-color);
  }
  .contact-us-landing-heading-part-2 {
    color: white;
}
}

@media all and (max-width: 550px) {
  /* .contactus-form-container {
    background: #000000;
  } */
  /* .contact-us-landing-section {
    background-image: url("../../../../assets/simpleLayout/contactus/3.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 435px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  } */
  .contactus-landing-heading {
    font-size: var(--mobile-font-40-px);
    font-weight: 800;
    line-height: 48px;
  }
  .contactus-landing-desc {
    text-align: left;
    font-size: var(--mobile-font-20-px);
    font-weight: 400;
    line-height: normal;
    margin-top: 0px;
  }
  .contact-us-landing-headings {
    gap: 15px;
    padding-right: 69px;
  }

  .contact-us-landing-br-2 {
    display: none;
  }

  .contactus-landing-mobile-optiona {
    margin-top: 5px;
  }

  .contact-us-landing-container {
    height: calc(85vh - 56px);
  }
  .contactus-landing-img {
    height: 100vh;
  }
}
