@import url("./DashboardTheme.css");

.dashboard-background {
  background: var(--dashboard-background-color);
}

.dashboard-container {
  padding: var(--dashboard-body-container-padding);
  height: var(--dashboard-body-view-height);
  overflow-y: auto;
  background: var(--dashboard-background-color);
  /* position: relative; */
}

@media all and (max-width: 1210px) {
  .dashboard-container {
    padding: 0 20px 0 20px;
  }
}

@media all and (max-width: 550px) {
  .dashboard-container {
    padding: 0 0px 0 0px;
  }
  .db-mgmt-header-search-add {
    padding: 0 18px;
  }
}

@media all and (min-width: 1920px) {
  .dashboard-container-main {
    max-width: 1800px;
    min-width: 1500px;
  }
  .dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
