.landing-container-events {
  position: relative;
  width: 100%;
  height: calc(100vh - 56px);
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.568); */
  padding-top: 0px;
}

.event-des-landing-image {
  position: absolute;
  top: 0;
  z-index: -10;
  width: 100%;
  object-fit: cover;
  height: 100%;
  filter: blur(2px) brightness(0.3);
}

.events-landing-heading {
  color: #ff8d27;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-65-px);
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 67.2px */
}

.events-landing-description {
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-25-px);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  width: 50vw;
  margin-top: 31px;
}

@media all and (max-width: 550px) {
  .landing-container-events {
    height: calc(100vh - 54px);
  }
  .events-landing-description {
    width: 95vw;
    color: var(--white-100, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: var(--mobile-font-18-px);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  .events-landing-heading {
    color: #ff8d27;
    text-align: center;
    font-family: Roboto;
    font-size: var(--mobile-font-40-px);
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
}
