.popup-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.377);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  z-index: 500;
  transition: opacity 0.5s, visibility 0.5s;
}

.popup-main {
  background-color: var(--white-color);
  padding: 30px;
  font-weight: bold;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  min-width: 200px;
  margin: 20px;
}

.popup-container-active {
  opacity: 1;
  /* target opacity */
  visibility: visible;
  /* target visibility */
}

.popup-question-mark {
  width: 150px;
}

.popup-divider {
  width: 100%;
  height: 2px;
  background-color: var(--popup-divider-background);
  margin: 10px 0px 16px 0px;
}

.popup-button {
  background-color: var(--primary-color);
  margin: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 125px;
  border-radius: 30px;
  font-weight: bold;
  color: var(--white-color);
}

.popup-button:hover {
  background-color: var(--black-color);
  color: var(--primary-color);
}

.popup-icon {
  border: none;
  background: none;
}
