/* pagination css */
.pagination-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 10px;
  gap: 5px;
}

.pagination-container button {
  cursor: pointer;
  /* margin-right: 6px; */
  /* margin-top: 10px; */
  background: var(--pagination-container-button-background);
  border: none;
  padding: 5px 10px;
  color: var(--pagination-container-button);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-container button.active {
  background: var(--black-color);
  color: var(--white-color);
}

.pagination-container button:disabled {
  color: var(--pagination-container-button-disabled);
  background-color: var(--white-color);
}
