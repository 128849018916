.add-events-file-upload-label input {
  display: none;
}

.add-events-file-upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 40px;
}

.add-event-image-name-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.add-event-image-file-name {
  padding: 5px 10px 5px 10px;
  max-width: 150px;
  text-wrap: nowrap;
  /* width: 200px; */
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  color: #434343;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.add-events-browse-button {
  margin-top: 16px;
  padding: 8px 15px;
  border-radius: 10px;
  color: white;
  transition: all 0.3s;
  width: 100%;
  color: #303972;
  font-family: Inter;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #c1bbeb;
  background: var(--white-100, #fff);
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.add-events-main-container-left {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  box-sizing: border-box;
  gap: 20px;
}

.add-events-main-container {
  display: flex;
  gap: 50px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

@media all and (max-width: 1160px) {
  .add-events-main-container {
    flex-direction: column;
  }
  .add-events-main-container-left {
    gap: 20px;
  }
  .add-events-browse-button {
    margin-top: 16px;
  }
  .add-events-main-container {
    gap: 20px;
  }
}

@media all and (max-width: 632px) {
  .emtpty-field-db-form-add-events::after {
    content: "Venue *";
  }
  .add-events-main-container-left {
    flex-direction: column;
  }
}
