.careers-page-landing-title {
  font-size: var(--font-90-px);
}

.careers-page-landing-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
}

.careers-page-landing-pic {
  width: 600px;
  margin-right: 3vw;
}

.career-page-landing-line-dark {
  color: var(--black-70, #3d3d3d);
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}

.career-page-landing-line-color {
  color: var(--primary-color);
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}

.career-landing-mobile-view-image {
  display: none;
}

.career-page-break-2 {
  display: none;
}
@media all and (max-width: 1200px) {
  .career-page-break,
  .career-landing-colon {
    display: none;
  }
  .careers-page-landing-container {
    /* flex-direction: column; */
    gap: 50px;
  }
  .careers-page-landing-title {
    text-align: left;
  }
  .careers-page-landing-pic {
    width: 463px;
    margin-right: 0vw;
  }
  .careers-page-landing-title {
    font-size: var(--font-65-px);
  }
}

@media all and (max-width: 896px) {
  .careers-page-career-items {
    grid-template-columns: 1fr;
  }
}

@media all and (max-width: 830px) {
  .careers-page-landing-pic {
    width: 400px;
    margin-right: 0vw;
  }
}

@media all and (max-width: 550px) {
  .career-page-break-2 {
    display: block;
  }
  .carrier-desc-container-sections {
    gap: 12px;
  }
  .career-brief-list {
    margin-inline-start: -25px;
    /* font-size: 20px; */
    line-height: 30px;
    color: black;
    font-weight: 500;
  }
  .career-page-landing-line-dark {
    color: black;
  }
  .career-page-break {
    display: block;
  }
  .careers-page-landing-pic-container {
    /* display: none; */
    background: blue;
    width: 100%;
    position: absolute;
    right: -192px;
    top: -89px;
    /* left: 0px; */
  }

  .careers-page-landing-container {
    margin-top: 0px;
  }

  .careers-page-landing-title {
    text-align: left;
  }

  .careers-page-landing-title {
    font-size: var(--mobile-font-30-px);
  }

  .careers-page-landing-container {
    align-items: flex-start;
    position: relative;
    /* background: aquamarine; */
    height: 286px;
  }

  /* .career-landing-mobile-view-image {
    display: block;
  } */
  .careers-page-landing-pic {
    position: absolute;
    width: 380px;
    top: 70px;
    /* right: -167px; */
    clip-path: inset(0 167px 13px 0px);
    z-index: -1;
    /* overflow: hidden; */
  }
  .careers-page-landing-pic {
    position: absolute;
    right: 0px;
  }
}

@media all and (min-width: 1900px) {
  .careers-page-landing-title {
    font-size: var(--font-65-px);
  }
}
