.projects-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 50px;
}

.portfolio-project-main-project {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  display: flex;
  position: absolute;
  height: 100%;
  z-index: 50;
}

.portfolio-project-main-project-container {
  position: relative;
}

@media all and (max-width: 520px) {
  .portfolio-project-main-project-container {
    position: relative;
    height: 295px;
  }
}

.portfolio-project-main-project-text {
  bottom: 60px;
  left: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 51;
  position: absolute;
}

.portfolio-project-main-project-text-type {
  font-family: "Roboto Flex";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2px;
  background: linear-gradient(0deg, #ede14f 0%, #ede14f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.portfolio-project-main-project-text-heading {
  /* width: 500px; */
  color: var(--black-0, var(--white-100, #fff));
  font-family: Epilogue;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  margin-right: 90px;
}

.portfolio-project-sub-projects-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.portfolio-other-project-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0px 5.662px 14.154px 0px rgba(0, 0, 0, 0.13);
  border-radius: 20px;
  padding: 10px;

  
  border-radius: 12px;
  border: 1px solid rgba(216, 216, 216, 0.4);
  background: var(--white-100, #fff);
  box-shadow: 0px 67.114px 109.061px 0px rgba(0, 0, 0, 0.05);
}

.portfolio-other-project-heading,
.portfolio-other-project-desc {
  padding: 0px 10px 0px 10px;
}

.portfolio-other-project-desc {
  text-align: justify;
}

.portfolio-other-project-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
}

.portfolio-other-project-heading {
  color: var(--black-90, #212121);
  font-family: Epilogue;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 500;
  line-height: 19.876px; /* 124.226% */
  /* Extra */
  text-align: center;
}
.portfolio-other-project-desc {
  color: var(--black-50, #666);
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 106.479% */
}

@media all and (max-width: 1160px) {
  .projects-container {
    grid-template-columns: 1fr;
  }
}

@media all and (max-width: 1160px) {
  .portfolio-project-sub-projects-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (max-width: 550px) {
  .portfolio-project-main-project-text-type {
    font-family: "Roboto Flex";
    font-size: var(--mobile-font-20-px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2px;
  }
  .portfolio-project-main-project-text-heading {
    margin-right: 40px;
    color: var(--black-0, var(--white-100, #fff));
    font-family: Epilogue;
    font-size: var(--mobile-font-24-px);
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }
}

@media all and (max-width: 520px) {
  .portfolio-project-main-project-text {
    bottom: 25px;
    left: 30px;
  }
  .portfolio-project-main-project {
    width: 100%;
    height: 295px;
    object-fit: cover;
    border-radius: 20px;
  }
  .portfolio-project-sub-projects-container {
    grid-template-columns: 1fr;
  }

  .portfolio-other-project-desc {
    display: none;
  }

  .portfolio-other-project-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0px;
    box-shadow: 0px 5.662px 14.154px 0px rgba(0, 0, 0, 0.13);
    border-radius: 20px;
    padding: 0px;
    background-color: black;
  }

  .portfolio-other-project-heading {
    padding: 30px 15px 19px 15px;
    position: absolute;
    bottom: 0px;
    text-align: left;
    color: var(--black-0, var(--white-100, #fff));
    font-family: Epilogue;
    font-size: var(--mobile-font-24-px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .portfolio-other-project-image {
    width: 100%;
    height: 259px;
    object-fit: cover;
    background-color: #000000a9;
    border-radius: 20px;
    filter: opacity(0.5);
  }
}

@media all and (min-width: 1920px) {
  .portfolio-other-project-desc {
    line-height: 25.907px;
  }

  .portfolio-other-project-heading {
    line-height: 26.876px;
  }
}
