.testimonial-input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.wrapper {
  width: 80vw;
  display: flex;
}

.content {
  width: 80vw;
  height: 100%;
}

.slide {
  width: 0;
}

.testimonialSlider {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  margin-bottom: 80px;
  gap: 15px;
}

.span-testimonial {
  background-color: white;
  text-align: center;
  line-height: 4rem;
  width: 4rem;
  border-radius: 8rem;
  color: #616161;
  font-size: 2rem;
}

.content {
  opacity: 0;
  /* pointer-events: none; */
  /* transition: all 2s; */
  /* max-height: 90vh; */
  /* overflow-y: auto; */
}

@media all and (min-width: 1750px) {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}

.testimonial-label {
  color: #e0e0e0;
  transition-duration: 1s;
  cursor: pointer;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#i_1:checked ~ .wrapper .content1,
#i_2:checked ~ .wrapper .content2,
#i_3:checked ~ .wrapper .content3 {
  opacity: 1;
  pointer-events: auto;
}

#i_1:checked ~ .controls .testimonial-label[for="i_1"],
#i_2:checked ~ .controls .testimonial-label[for="i_2"],
#i_3:checked ~ .controls .testimonial-label[for="i_3"] {
  color: var(--primary-color);
}
