.customer-base-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  text-align: center;
  flex-direction: column;
}

.customer-base-orange-heading {
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-48-px);
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
}
.customer-base-black-heading {
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-48-px);
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
}

.customer-base-image {
  width: 90vw;
}
@media all and (min-width: 1750px) {
  .customer-base-image {
    width: 1600px;
  }
}

@media all and (max-width: 550px) {
  .customer-base-polygon {
    display: none;
  }
  .customer-base-orange-heading, .customer-base-black-heading{
    font-size: var(--mobile-font-25-px); line-height: 40px;
  }
}
