/* 1px solid #c1bbeb */

/* Components */
.add-blogs-element-common {
  padding: 0.5rem;
  padding-left: 20px;
  border: none;
  outline: none;
  width: 100%;
}

.add-blog-heading {
  border-right: 1px solid #c1bbeb;
  padding-left: 15px;
  font-size: 17px;
  font-weight: 600;
  text-align: justify;
}
.add-blog-heading::placeholder {
  font-size: 17px;
}

.add-blog-paragraph {
  padding-left: 30px;
  border-right: 1px solid #c1bbeb;
  font-size: 17px;
  text-align: justify;
}
.add-blog-paragraph::placeholder {
  font-size: 17px;
}

.add-blog-image-source {
  border: 2px dashed #c1bbeb;
  border-top: none;
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  padding-right: 20px;
}
.add-blog-image-source::placeholder {
  font-size: 14px;
}

.add-blogs-dropzone-size {
  width: 100%;
  height: 300px;
}

@media all and (max-width: 550px) {
  .add-blog-paragraph {
    font-size: 14px;
  }
}
