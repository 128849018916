.nav-container {
  display: flex;
  /* width: 100%; */
  align-items: center;
  /* padding-left: 60px; */
  /* padding-right: 60px; */
  justify-content: space-between;
  box-sizing: border-box;
  margin: 23px 49px 23px 49px;
  background: #303030;
  border-radius: 28px;
}

.nav-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

.nav-logo {
  width: auto;
  height: 24.992px;
  margin-left: 25px;
  vertical-align: middle;
  margin-bottom: 3px;
}
.nav-link {
  text-decoration: none;
  color: white;
}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link:hover .nav-link-caret {
  color: var(--primary-color);
}

/* .nav-link-down-caret {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
} */
.nav-link-caret {
  color: var(--caret-down-color);
}
.nav-link-get-in-touch {
  text-decoration: none;
  display: flex;
  padding: 16px 30px 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #f16a23;
  box-shadow: 0px 4px 30px 0px rgba(241, 106, 34, 0.37);
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

nav li {
  list-style: none;
  font-size: var(--font-16-px);
}

nav {
  position: relative;
  top: 0px;
  z-index: 999;
}

.nav-logo-sidebar {
  width: auto;
  height: 28px;
  vertical-align: middle;
}

/* Dropdown menu */
.dropdown-menu-nav {
  display: none;
}

.nav-container ul {
  padding: 0px;
  margin: 0px;
}

.nav-container ul li {
  /* padding: 10px 15px; */
  position: relative;
}

.nav-container ul li:hover .dropdown-menu-nav {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  border-radius: 10px;
  z-index: 500;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.18), 0px 2px 8px rgba(0, 0, 0, 0.04);
}

.nav-container ul li:hover .dropdown-menu-nav ul {
  display: block;
  /* margin: 10px; */
}

.nav-container ul li:hover .dropdown-menu-nav li {
  width: 120px;
  /* padding: 10px; */
}

.side-bar-close-button {
  font-size: var(--font-32-px);
  position: absolute;
  top: 10px;
  right: 9px;
}
/* Side Bar */
.sidebar-container {
  display: none;
  width: 300px;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  right: 0px;
  top: 0px;
  height: 100vh;
  background-color: white;
  background-color: #303030;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.18), 0px 2px 8px rgba(0, 0, 0, 0.04);
}

.sidebar-content {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.appear-sidebar {
  opacity: 0;
  animation: opacityOn 0.5s normal forwards;
  /* animation-delay: 2s; */
}
.disapear-sidebar {
  opacity: 0;
  animation: opacityOff 0.5s normal forwards;
}
@keyframes opacityOn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacityOff {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

.sidebar-container-active {
  display: flex;
}

.menu-bar {
  color: white;
  display: none;
}

.sidebar-logo {
  display: none;
}

.sidebar-footer {
  display: none;
  /* background-color: var(--primary-color); */
  height: 3vh;
  width: 100vw;
}

@media all and (max-width: 1142px) {
  .nav-container {
    position: sticky;
  }
  .sidebar-footer {
    display: block;
  }
  .nav-link {
    text-decoration: none;
    color: white;
  }
  .nav-link-container {
    display: none;
  }
  .sidebar-container {
    justify-content: center;
  }
  .menu-bar {
    display: block;
  }

  .nav-container {
    margin: unset;
    border-radius: unset;
    padding: 15px;
    /* padding: 8px; */
    /* padding-top: 20px; */
    /* margin-top: 0px; */
  }
}

@media all and (max-width: 817px) {
  /* .nav-logo {
    height: 16px;
  } */
  .nav-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media all and (max-width: 550px) {
  .nav-logo {
    margin-left: -7px;
  }
}

@media all and (max-width: 436px) {
  .nav-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-container {
    width: 100vw;
    justify-content: space-between;
  }

  .sidebar-logo {
    display: block;
    margin-top: 83px;
  }

  .side-bar-close-button {
    right: 10px;
  }
}
