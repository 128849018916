.our-services-card-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 40px;
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3); */
  /* border-radius: 10px; */
  z-index: 101;
  background-color: var(--white-color);

  border-radius: 12px;
  border: 1px solid rgba(216, 216, 216, 0.4);
  background: var(--white-100, #fff);
  box-shadow: 0px 67.114px 109.061px 0px rgba(0, 0, 0, 0.05);
}
.our-services-card-main-container:hover {
  background-color: var(--secondary-color);
}
.our-services-card-main-container:hover .our-services-card-description {
  color: var(--white-color);
}

.our-services-card-main-container:hover .our-services-card-sub-heading {
  color: var(--white-color);
}
.our-services-card-main-container:hover .our-services-card-main-heading {
  color: var(--white-color);
}

/* .our-services-card-main-container:hover .our-service-card-icon {
  filter: brightness(0) invert(1);
} */

.our-service-card-icon {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

.our-services-card-description {
  text-align: justify;
  color: var(--Paragraph, #7b7b7b);
  /* text-align: center; */
  font-family: Poppins;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.our-services-card-sub-heading {
  color: var(--Paragraph, #7b7b7b);
  font-family: Poppins;
  font-size: var(--font-12-8-px);
  font-style: normal;
  font-weight: 500;
  line-height: 19.2px; /* 150% */
}

.our-services-card-main-heading {
  color: var(--Black, #000000);
  font-family: Poppins;
  font-size: var(--font-25-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media all and (max-width: 620px) {
  .our-services-card-main-container {
    border-radius: 35.386px;
    background: var(--white-100, #fff);
    box-shadow: 0px 5.662px 14.154px 0px rgba(0, 0, 0, 0.13);
  }
}

@media all and (max-width: 550px) {
  /* .our-service-card-icon {
    width: 40px;
  } */
  .our-services-card-main-heading {
    text-align: left;
    color: var(--black-70, #3d3d3d);
    /* font-family: Epilogue; */
    font-size: var(--mobile-font-20-px);
    font-style: normal;
    font-weight: 500;
    /* line-height: 40px; */
    line-height: normal;
  }

  .our-services-card-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 31px;
    z-index: 101;
    border-radius: 35.386px;
    background: var(--white-100, #fff);
    box-shadow: 0px 5.662px 14.154px 0px rgba(0, 0, 0, 0.13);

    border-radius: 12px;
    border: 1px solid rgba(216, 216, 216, 0.4);
    background: var(--white-100, #fff);
    box-shadow: 0px 67.114px 109.061px 0px rgba(0, 0, 0, 0.05);
  }

  .our-services-card-main-container:hover .our-services-card-description {
    text-align: justify;
  }

  .our-services-card-description {
    text-align: justify;
  }

  .our-services-card-sub-heading {
    /* display: none; */
    color: black;
  }

  .our-services-card-description {
    font-size: var(--mobile-font-12-px);
  }
}
