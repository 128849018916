.eventsgallerycard2-container {
  display: flex;
  gap: 85px;
  align-items: center;
}

.eventsgallerycard2-desc-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-around;
}

.eventsgallerycard2-image-container {
  width: 566px;
  height: 539px;
  /* background-color: green; */
  position: relative;
  /* flex: 1; */
}

.eventsgallerycard2-img-1 {
  position: absolute;
  left: 0px;
  top: 46px;
  width: 320px;
  height: 220px;
  object-fit: cover;
  border-radius: 24px;
}

.eventsgallerycard2-img-2 {
  position: absolute;
  right: 0px;
  width: 220px;
  height: 250px;
  /* display: none; */
  object-fit: cover;
  border-radius: 24px;
}

.eventsgallerycard2-img-3 {
  width: 220px;
  height: 250px;
  object-fit: cover;
  border-radius: 24px;
  /* display: none; */
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.eventsgallerycard2-img-4 {
  width: 320px;
  height: 220px;
  object-fit: cover;
  /* display: none; */
  border-radius: 24px;
  position: absolute;
  right: 0px;
  bottom: 31px;
}

.eventsgallerycard2-img-1-duplicate,
.eventsgallerycard2-img-2-duplicate,
.eventsgallerycard2-img-3-duplicate,
.eventsgallerycard2-img-4-duplicate {
  margin-top: 20px;
  filter: blur(16px);
  z-index: -1;
}

.eventsgallerycard2-img-3-duplicate,
.eventsgallerycard2-img-4-duplicate {
  margin-top: 30px;
  filter: blur(16px);
  z-index: -1;
}

.egc2-row-rev {
  flex-direction: row-reverse;
}

.egc2-row {
  flex-direction: row;
}

@media all and (max-width: 1033px) {
  .eventsgallerycard2-container {
    flex-direction: column-reverse;
    gap: 30px;
  }
  .eventsgallerycard2-desc-container {
    justify-content: center;
    align-items: center;
  }
}
@media all and (max-width: 600px) {
  .eventsgallerycard2-container {
    flex-direction: column-reverse;
    gap: 0px;
  }
  .eventsgallerycard2-desc-container {
    gap: 20px;
}
}
