.exception-container {
  margin: 30px 0px 30px 0px;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.exception-container img {
  width: 150px;
  height: auto;
  filter: grayscale(100%);
}
