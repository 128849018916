.blogs-description-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 70px;
}

.blogs-description-title {
  color: #f5af30;
  font-family: Poppins;
  font-size: var(--font-65-px);
  font-style: normal;
  font-weight: 800;
  line-height: 48px; /* 53.333% */
  letter-spacing: 1px;
}

.blog-description-main-container {
  padding: 70px 70px 0px 70px;
}

.blogs-description-body {
  padding: 20px 70px 0px 70px;
  border-radius: 20px;
  display: flex;
  margin-top: 15px;
  gap: 16px;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.31);
}

@media all and (max-width: 1200px) {
  .blogs-description-body {
    padding: 0px;
  }
  .blogs-description-header {
    padding: 0px;
  }
}

@media all and (max-width: 1100px) {
  .blogs-description-body {
    padding: 0px 10px 0px 10px;
  }
}

.blogs-description-body-image {
  width: 100%;
  height: 279px;
  object-fit: cover;
  border-radius: 10px;
}

.blogs-description-body-heading {
  color: var(--Black-100, var(--Black-100, #07070c));
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-45-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.blogs-description-body-date {
  color: var(--Black-100, var(--Black-100, #07070c));
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-22-px);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 5px;
}

.blogs-description-body-description {
  border-radius: 10px;
  border: 1.5px solid var(--Black-10, #f4f4f6);
  padding: 10px 20px 10px 20px;
  color: var(--Black-50, var(--Black-50, #6e727e));
  font-family: Poppins;
  font-size: var(--font-25-px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: justify;
}

.blogs-description-body-heading-container {
  border-radius: 10px;
  border: 1.5px solid var(--Black-10, #f4f4f6);
}

/* circles */
.blog-description-circle-1 {
  position: absolute;
  width: 250px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #ffdf74;
  z-index: -100;
  top: 850px;
  left: 10px;
}

.blog-description-circle-2 {
  position: absolute;
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #f5f0c2;
  z-index: -100;
  top: 800px;
  right: -80px;
  clip-path: inset(0px 80px 0 0px);
}

.blog-description-circle-3 {
  position: absolute;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #f5f0c2;
  z-index: -100;
  top: 1200px;
  left: 500px;
}

.blog-description-circle-4 {
  position: absolute;
  width: 500px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #f5f0c2;
  z-index: -100;
  top: 1100px;
  left: 800px;
}
@media all and (max-width: 1460px) {
  .blog-description-circle-4 {
    width: 300px;
    height: 300px;
  }
}

@media all and (max-width: 1200px) {
  .blog-description-circle-4 {
    width: 200px;
    height: 200px;
  }
}

@media all and (max-width: 1050px) {
  .blog-description-circle-4 {
    display: none;
  }
}

.blogs-description-body-tags {
  display: flex;
  justify-content: space-around;
}

.blogs-description-body-tags-container {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.blog-description-used-topic {
  border-radius: 999px;
  background: #ffe99f;
  color: #212529;
  text-align: center;
  font-family: "Work Sans";
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding: 10px 20px 10px 20px;
}

.blogs-description-more-blogs {
  margin-top: 60px;
}
.blogs-description-more-blogs-main-heading {
  font-size: var(--font-48-px);
  text-align: center;
  color: var(--Black, #373737);
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 58.59px;
  margin-bottom: 50px;
}

.blogs-description-more-blogs-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

@media all and (max-width: 1200px) {
  .blogs-description-more-blogs-item {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
}

@media all and (max-width: 968px) {
  .blog-desc-polygon-1 {
    display: none;
  }
}

@media all and (max-width: 880px) {
  .blog-description-main-container {
    padding: 0px;
  }
  .blogs-description-body-heading {
    font-size: var(--font-36-px);
  }
}

@media all and (max-width: 710px) {
  .blog-description-circle-1,
  .blog-description-circle-3,
  .blog-description-circle-4,
  .blogs-description-search {
    display: none;
  }

  .blogs-description-body-tags-container {
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 0px 10px 0px 10px;
  }
  .blogs-description-title {
    display: none;
  }
  .blogs-description-body {
    margin-top: 22px;
  }
}

@media all and (max-width: 550px) {
  .blog-view-para {
    font-size: var(--mobile-font-17-px);
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blogs-description-more-blogs {
    margin-top: 60px;
    padding: 0px;
  }
  .blogs-description-body-image-container {
    margin-top: -45px;
    margin-left: -20px;
    margin-right: -20px;
  }
  .blogs-description-body-heading-container {
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    left: 0px;
    top: 59px;
    border: none;
    color: white;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 318px;
  }

  .blogs-description-body-image {
    height: 318px;
    border-radius: 0px;
    filter: blur(-4px);
    filter: brightness(0.5);
  }
  .blogs-description-header {
    display: none;
  }
  .blogs-description-body {
    margin-top: 0px;
    gap: 4px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .blogs-description-body-heading {
    font-size: var(--mobile-font-20-px);
    font-weight: 500;
    margin-top: 0px;
    color: white;
    background: #00000040;
  }
  .blogs-description-body-date {
    color: white;
    background: #00000040;
    font-size: var(--mobile-font-15-px);
  }
  .blogs-description-body-description {
    border: none;
    padding: 0px 10px 10px 10px;
    font-size: var(--font-14-px);
  }
  .blog-description-used-topic {
    font-size: var(--mobile-font-16-px);
  }
  .blogs-description-more-blogs-main-heading {
    font-size: var(--mobile-font-28-px);
    margin-bottom: 7px;
  }
}

/* blog view */
.blog-view-image {
  width: 60%;
  border-radius: 20px;
}

.blog-view-image-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
  align-items: center;
}

.blog-view-heading {
  font-size: var(--font-24-px);
  color: black;
  margin-bottom: 3px;
}

.blog-view-img-src {
  font-size: var(--font-14-px);
  font-weight: normal;

}

.blog-view-para {
  margin-bottom: 10px;
}