.db-edit-delete-form-container {
  border-radius: 20px;
  background: var(--white-100, #fff);
  min-height: 50vh;
  width: 100%;
  height: calc(100vh - 107px - 167px);
  overflow: auto;
}

@media all and (max-width: 1210px) {
  .db-edit-delete-form-container {
    height: calc(100vh - 107px - 130px);
  }
}
@media all and (max-width: 800px) {
  .db-edit-delete-form-container {
    height: unset;
  }
}

.add-edit-navi {
  display: flex;
  margin: 10px 0px 20px 0px;
  gap: 2px;
}

.add-edit-navi-item {
  background-color: rgba(209, 209, 209, 0.541);
  padding: 5px 10px 5px 10px;
  color: black;
  font-weight: 500;
  text-decoration: none;
  font-size: var(--font-20-px);
  /* border-radius: 20px; */
}

.add-edit-navi-item:first-child {
  padding-left: 20px;
  border-radius: 20px 0px 0px 20px;
}
.add-edit-navi-item:last-child {
  padding-right: 29px;
  clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);
}

.db-edit-delete-header {
  background-color: black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 20px 10px 20px;
  color: white;
}
.db-edit-delete-heading {
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.db-edit-delete-children {
  padding: 24px 45px 43px 45px;
}
.add-edit-navigation {
  font-size: 16px;
  color: rgb(77, 77, 77);
  font-weight: 500;
  margin-bottom: 20px;
}

.add-edit-navigation a {
  color: rgb(77, 77, 77);
  text-decoration: none;
  font-weight: normal;
}

@media all and (max-width: 550px) {
  .db-edit-delete-header {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .db-edit-delete-form-container {
    border-radius: 0px;
  }
  .add-edit-navi-item {
    font-size: var(--mobile-font-16-px);
  }
  .add-edit-navi-item:first-child {
    padding-left: 20px;
    border-radius: 0px 0px 0px 0px;
  }
  .db-edit-delete-children {
    padding: 24px 20px 43px 20px;
  }
  .db-edit-delete-heading {
    font-size: var(--mobile-font-14-px);
  }
  .add-edit-navigation {
    font-size: 14px;
    margin-left: 11px;
  }
}
