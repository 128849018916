.eventsgallerycard1-container {
  display: flex;
  gap: 140px;
  align-items: center;
}

.eventsgallerycard1-image-container {
  width: 250px;
  height: 280px;
  /* background-color: green; */
  position: relative;
  /* flex: 1; */
}

.eventsgallerycard1-desc-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-around;
}

.eventsgallerycard1-img-1-duplicate,
.eventsgallerycard1-img-2-duplicate {
  margin-top: 20px;
  filter: blur(31px);
  width: 250px;
  height: 280px;
  z-index: -1;
}

.eventsgallerycard1-img-1 {
  opacity: 0.3;
  width: 220px;
  height: 250px;
  position: absolute;
  right: 50px;
  bottom: 50px;
  object-fit: cover;
  border-radius: 24px;
}
.eventsgallerycard1-img-2 {
  width: 220px;
  height: 250px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  object-fit: cover;
  border-radius: 24px;
}
.egc1-row {
  flex-direction: row;
}

.egc1-row-rev {
  flex-direction: row-reverse;
}

@media all and (max-width: 1033px) {
  .eventsgallerycard1-container {
    flex-direction: column;
    gap: 30px;
  }
  .eventsgallerycard1-desc-container {
    justify-content: center;
    align-items: center;
  }
}

@media all and (max-width: 600px) {
  .eventsgallerycard1-container {
    flex-direction: column;
    gap: 0px;
  }
}
