@import "./theme/theme.css";

body {
  margin: 0;
}

* {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* *,
*::before,
*::after {
  box-sizing: border-box;
} */

.home-top-space {
  margin-top: 10vh;
}

.form-error-message {
  font-size: 14px;
  color: red;
  font-weight: 500;
}

.dashboard-form-delete-button {
  border: none;
  background-color: transparent;
}

@media all and (max-width: 550px) {
  .home-top-space {
    margin-top: 5vh;
  }
  .db-add-hide-on-mobile {
    display: none;
  }
}

@media all and (max-width: 926px) {
  .emtpty-field-db-form {
    display: none;
  }
}

.infinite-loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px 150px 0px;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.658);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
