.loader1 {
  margin-left: 100px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background-color: #000000d1;
  z-index: 500;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s, visibility 0.5s;
}

.loader1-deactive {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
}
.loader1::before,
.loader1::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48em;
  height: 48em;
  background-image: radial-gradient(
      circle 10px,
      var(--primary-color) 100%,
      transparent 0
    ),
    radial-gradient(circle 10px, var(--primary-color) 100%, transparent 0),
    radial-gradient(circle 10px, var(--primary-color) 100%, transparent 0),
    radial-gradient(circle 10px, var(--primary-color) 100%, transparent 0),
    radial-gradient(circle 10px, var(--primary-color) 100%, transparent 0),
    radial-gradient(circle 10px, var(--primary-color) 100%, transparent 0),
    radial-gradient(circle 10px, var(--primary-color) 100%, transparent 0),
    radial-gradient(circle 10px, var(--primary-color) 100%, transparent 0);
  background-position: 0em -18em, 0em 18em, 18em 0em, -18em 0em, 13em -13em,
    -13em -13em, 13em 13em, -13em 13em;
  background-repeat: no-repeat;
  font-size: 0.5px;
  border-radius: 50%;
  animation: blast 1s ease-in infinite;
}
.loader1::after {
  font-size: 1px;
  background: var(--primary-color);
  animation: bounce 1s ease-in infinite;
}

@keyframes bounce {
  0%,
  100% {
    font-size: 0.75px;
  }
  50% {
    font-size: 1.5px;
  }
}
@keyframes blast {
  0%,
  40% {
    font-size: 0.5px;
  }
  70% {
    opacity: 1;
    font-size: 4px;
  }
  100% {
    font-size: 6px;
    opacity: 0;
  }
}

@media all and (max-width: 1210px) {
  .loader1 {
    margin-left: 0px;
  }
}
