:root {
  --dashboard-header-container-padding: 20px 20px 20px 360px;
  --dashboard-body-container-padding: 0px 40px 0px 360px;
  --dashboard-body-view-height: calc(100vh - 107px);
  --dashboard-background-color: #f3f4ff;
  --dashboard-main-text-color: #303972;
}

@media all and (max-width: 1210px) {
  :root {
    --dashboard-body-view-height: calc(100vh - 87px);
  }
}

