.blog-preview-container {
  padding: 20px;
  gap: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  border: 1px solid rgba(216, 216, 216, 0.4);
  background: var(--white-100, #fff);
  box-shadow: 0px 67.114px 109.061px 0px rgba(0, 0, 0, 0.05);
}

.blog-preview-image {
  /* width: 100%; */
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  height: 200px;
}

.blog-preview-is-new {
  color: #f16a23;
  font-family: Poppins;
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-preview-main-heading {
  color: var(--Black-100, #07070c);
  font-family: Poppins;
  font-size: var(--font-32-px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog-preview-description {
  color: var(--Black-100, #07070c);
  font-family: Poppins;
  font-size: var(--font-20-px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog-preview-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-preview-view-count {
  color: #6e727e;
  font-family: Poppins;
  font-size: var(--font-14-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-preview-read-button {
  cursor: pointer;
  display: flex;
  padding: 10px 30px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  background: #f16a23;
}

.blog-preview-read-button a {
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  color: var(--white-color);
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-16-px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media all and (max-width: 764px) {
  .blog-preview-container {
    border-radius: 20px;
    border: 1px solid var(--Black-10, #f4f4f6);
  }
}

@media all and (max-width: 550px) {
  .blog-preview-image {
    border-radius: 15px;
    height: 175px;
  }
  .blog-preview-is-new {
    font-size: var(--mobile-font-18-px);
    font-weight: 400;
  }
  .blog-preview-main-heading {
    font-size: var(--mobile-font-18-px);
    font-weight: 600;
  }
  .blog-preview-description {
    color: var(--Black-20, #b3b3ba);
    font-size: var(--mobile-font-14-px);
    font-weight: 400;
  }

  .blog-preview-view-count {
    color: #6e727e;
    font-family: Poppins;
    font-size: var(--mobile-font-14-px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .blog-preview-read-button {
    cursor: default;
    padding: 0px;
    color: #f16a23;
    background: transparent;
  }
  .blog-preview-read-button a {
    color: #f16a23;
    cursor: default;
    font-weight: 600;
    font-size: var(--mobile-font-16-px);
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .blog-preview-is-new {
    font-size: var(--mobile-font-18-px);
  }
  .blog-preview-main-heading {
    font-size: var(--mobile-font-18-px);
  }
  .blog-preview-description {
    font-size: var(--mobile-font-14-px);
  }
  .blog-preview-view-count {
    font-size: var(--mobile-font-14-px);
  }
}
